import React, { useEffect, useState } from "react";
import "../../CSS/ItemMaster.css";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import LoadingScreen from "../LoadingScreen";
import AllNav from "../../Components/Navbar/AllNav";

const ItemMasterReport = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [item, setItem] = useState([]);
  const [search, setSearch] = useState("");
  const [status, SetStatus] = useState("");
  const [isloading, setisloading] = useState(true);

  const customStylessSelect = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "250px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const filteredData = item.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const stat = status;

    if (searchData.length === 0 && !stat) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "ITEMCODE",
        "MAINNAME",
        "CATEGORY",
        "SUBCATEGORY",
        "SPECIFICATIONS",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const statusMatch =
      !status ||
      (status === "RAW MATERIALS"
        ? item.OM_ITEM_FINISHED_RAW_MATERIAL === "R"
        : status === "FINISHED PRODUCTS"
        ? item.OM_ITEM_FINISHED_RAW_MATERIAL === "F"
        : "");

    return textMatch && statusMatch;
  });

  const ExcelStockData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Item Master Report");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [15, 50, 25, 25, 25, 15];

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "ITEM CODE",
      "ITEM NAME",
      "CATEGORY",
      "SUBCATEGORY",
      "PRODUCT TYPE",
      "SIZE",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Item_Master_Report.xlsx";
      link.click();
    });
  };

  const PdfStockData = () => {
    const doc = new jsPDF();

    doc.text("Item Master - Report", 10, 10);

    const columns = [
      "ITEM CODE",
      "ITEM NAME",
      "CATEGORY",
      "SUBCATEGORY",
      "PRODUCT TYPE",
      "SIZE",
    ];
    const data = filteredData.map((stock) => [
      stock.ITEMCODE,
      stock.MAINNAME,
      stock.CATEGORY,
      stock.SUBCATEGORY,
      stock.OM_ITEM_FINISHED_RAW_MATERIAL === "R"
        ? "RAW MATERIAL"
        : stock.OM_ITEM_FINISHED_RAW_MATERIAL === "F"
        ? "FINISHED PRODUCT"
        : "N/A",
      stock.SPECIFICATIONS,
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("Item_Master_Report.pdf");
  };

  useEffect(() => {
    getItemDetails();
  }, []);

  const getItemDetails = () => {
    axios
      .get(`${BASEURL}/ItemDetails`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setItem(response.data);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Item details:", error);
      });
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AllNav />
      <h1 style={{ textAlign: "center" }}>Item Master - Report</h1>
      <section className="section__Item">
        <div className="section__div">
          <div className="item_code_table">
            <div className="select_product_type">
              <div>
                <p>Search</p>
              </div>
              <div>
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              <div>
                <p>Product Type</p>
              </div>
              <div>
                <Select
                  styles={customStylessSelect}
                  onChange={(e) => {
                    if (e.value === "") {
                      alert("Kindly Supply proper Product Type");
                      SetStatus("");
                    } else {
                      SetStatus(e.value);
                    }
                  }}
                  value={
                    status
                      ? { label: status, value: status }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    { value: "RAW MATERIALS", label: "RAW MATERIALS" },
                    { value: "FINISHED PRODUCTS", label: "FINISHED PRODUCTS" },
                  ]}
                />
              </div>
              <div>
                <RiFileExcel2Line onClick={ExcelStockData} size={35} />
              </div>
              <div>
                {" "}
                <BsFileEarmarkPdf onClick={PdfStockData} size={35} />
              </div>
            </div>
          </div>
        </div>

        <div className="visible">
          <table className="table_1">
            <thead>
              <tr className="table-header">
                <td>ITEM CODE</td>
                <td>ITEM NAME</td>
                <td>CATEGORY</td>
                <td>SUB CATEGORY</td>
                <td>PRODUCT TYPE</td>
                <td>SIZE</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((value, index) => (
                <tr>
                  <td style={{ textAlign: "right", paddingRight: "50px" }}>
                    {value.ITEMCODE ? value.ITEMCODE : "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.MAINNAME ? value.MAINNAME : "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.CATEGORY ? value.CATEGORY : "N/A"}
                  </td>
                  <td style={{ paddingLeft: "20px" }}>
                    {value.SUBCATEGORY ? value.SUBCATEGORY : "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.OM_ITEM_FINISHED_RAW_MATERIAL === "R"
                      ? "RAW MATERIAL"
                      : value.OM_ITEM_FINISHED_RAW_MATERIAL === "F"
                      ? "FINISHED PRODUCT"
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.SPECIFICATIONS ? value.SPECIFICATIONS : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default ItemMasterReport;
