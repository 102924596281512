import React, { useEffect, useState } from "react";
import "../../CSS/ItemMaster.css";
import axios from "axios";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import LoadingScreen from "../LoadingScreen";
import AllNav from "../../Components/Navbar/AllNav";

const PriceListReport = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [item, setItem] = useState([]);
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(true);

  const customStylessSelect = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "250px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const filteredData = item.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["ITEMCODE", "ITEMDESCRIPTION", "COSTPRICE"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  const ExcelStockData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Price List Report");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [15, 50, 25, 25];

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "ITEM CODE",
      "ITEM NAME",
      "SELLING PRICE EXCL VAT",
      "SELLING PRICE INCL VAT",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Price_List_Report.xlsx";
      link.click();
    });
  };

  const PdfStockData = () => {
    const doc = new jsPDF();

    doc.text("Price List - Report", 10, 10);

    const columns = [
      "ITEM CODE",
      "ITEM NAME",
      "SELLING PRICE EXCL VAT",
      "SELLING PRICE INCL VAT",
    ];
    const data = filteredData.map((stock) => [
      stock.ITEMCODE,
      stock.ITEMDESCRIPTION,
      stock.COSTPRICE
        ? stock.COSTPRICE.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "N/A",
      stock.COSTPRICE
        ? (1.16 * Number(stock.COSTPRICE)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "N/A",
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
      styles: {
        cellPadding: 0.5,
        fontSize: 10,
        valign: "middle",
        halign: "left", // Aligns all columns to the right
        columnWidth: "wrap",
      },
      columnStyles: {
        // Right-align the last two columns (numeric columns)
        2: { halign: "right" },
        3: { halign: "right" },
      },
    });

    doc.save("Price_List_Report.pdf");
  };

  useEffect(() => {
    getItemDetails();
  }, []);

  const getItemDetails = () => {
    axios
      .get(`${BASEURL}/ItemDetails`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setItem(response.data);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Item details:", error);
      });
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AllNav />
      <h1 style={{ textAlign: "center" }}>Price List - Report</h1>
      <section className="section__Item">
        <div className="section__div">
          <div className="item_code_table">
            <div className="select_product_type">
              <div>
                <p>Search</p>
              </div>
              <div>
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              <div>
                <p>Product Type</p>
              </div>
              <div>
                <RiFileExcel2Line onClick={ExcelStockData} size={35} />
              </div>
              <div>
                {" "}
                <BsFileEarmarkPdf onClick={PdfStockData} size={35} />
              </div>
            </div>
          </div>
        </div>

        <div className="visible">
          <table className="table_1">
            <thead>
              <tr className="table-header">
                <td>ITEM CODE</td>
                <td>ITEM NAME</td>
                <td>SELLING PRICE EXCL VAT</td>
                <td>SELLING PRICE INCL VAT</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((value, index) => (
                <tr>
                  <td style={{ textAlign: "right", paddingRight: "50px" }}>
                    {value.ITEMCODE ? value.ITEMCODE : "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.ITEMDESCRIPTION ? value.ITEMDESCRIPTION : "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingLeft: "108px" }}>
                    {value.COSTPRICE
                      ? value.COSTPRICE.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingLeft: "108px" }}>
                    {value.COSTPRICE
                      ? (1.16 * Number(value.COSTPRICE)).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default PriceListReport;
