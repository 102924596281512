import React, { useEffect, useState } from "react";
import "../CSS/ItemMaster.css";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AllNav from "../Components/Navbar/AllNav";
import LoadingScreen from "./LoadingScreen";

const ItemMaster = () => {
  const state = useSelector((state) => state.changeTheState.user);
  const username = state.emailId;
  const [date, setDate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [item, setItem] = useState([]);
  const [category, setCategory] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [classification, setClassification] = useState([]);
  const [List, setList] = useState([]);
  const [itemcode, setItemcode] = useState("");
  const [itemname, setItemname] = useState("");
  const [selcategory, setselcategory] = useState("");
  const [selsubcategory, setselsubcategory] = useState("");
  const [selclassification, setselclassification] = useState("");
  const [selbagsize, setselbagsize] = useState("");
  const [selspecification, setselspecification] = useState("");
  const [price, setprice] = useState("");
  const [description, setdescription] = useState("");
  const [selRaw, setselRaw] = useState("");
  const [selhistoricproducttype, setselhistoricproducttype] = useState("");
  const [fetchrow, setfetchrow] = useState(false);
  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    getCatgeory();
    getSubCatgeory();
    getClassification();
    getItemDetails();
  }, []);

  const getItemDetails = () => {
    axios
      .get(`${BASEURL}/ItemDetails`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setisloading(false);
        setItem(response.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Item details:", error);
      });
  };

  const getCatgeory = () => {
    axios
      .get(`${BASEURL}/ItemCategory`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setCategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Category:", error);
      });
  };

  const getSubCatgeory = () => {
    axios
      .get(`${BASEURL}/ItemSubCategory`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setSubcategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Category:", error);
      });
  };

  const getClassification = () => {
    axios
      .get(`${BASEURL}/ItemClassification`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setClassification(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Classification:", error);
      });
  };

  const getListOfItemsAfterProductType = (type) => {
    axios
      .post(
        `${BASEURL}/ListOfItemsAfterProductType`,
        {
          producttype: type === "RAW MATERIAL" ? "R" : "F",
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Raw data:", error);
      });
  };

  const handleRowClick = (value, index) => {
    setfetchrow(true);
    getHistoric(value.MAINNAME);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getHistoric = (item) => {
    console.log(item);
    axios
      .post(
        `${BASEURL}/FetchRawandFinished`,
        {
          itemname: item,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((resonse) => {
        setItemcode(resonse.data[0].ITEMCODE);
        setItemname(resonse.data[0].MAINNAME);
        setselcategory(resonse.data[0].CATEGORY);
        setselsubcategory(resonse.data[0].SUBCATEGORY);
        setselclassification(resonse.data[0].CLASSIFICATION);
        setselbagsize(resonse.data[0].SPECIFICATIONS);
        setselspecification(
          resonse.data[0].OM_ITEM_FINISHED_RAW_MATERIAL === "R"
            ? "RAW MATERIAL"
            : "FINISHED PRODUCT"
        );
        setprice(resonse.data[0].COSTPRICE);
        setdescription(resonse.data[0].ITEMDESCRIPTION);
      })
      .catch((error) => {
        console.error("Error fetching chargescost:", error);
      });
  };

  // Saving
  async function SaveItemMaster() {
    try {
      const response = await axios.post(
        `${BASEURL}/InsertItemMaster`,
        {
          itemcode: itemcode,
          itemname: itemname,
          itemdescription: description,
          category: selcategory,
          subcategory: selsubcategory,
          classification: selclassification,
          cost: price,
          size: selbagsize,
          rorf: selspecification,
          user: username,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        console.log(`Item Master saved successfully`);
      } else {
        console.error(`Error while saving Item Master`);
      }
    } catch (error) {
      console.error(`Error while saving Item Master`, error);
    }
  }

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AllNav />

      <h1 style={{ textAlign: "center" }}>Item Master</h1>
      <div className="header_import_import">
        <div className="import">
          <p>STOCK LIST</p>
        </div>
        <div className="flex">
          <button
            className="Save_import"
            onClick={() => {
              if (itemcode || itemname) {
                if (window.confirm("Are you sure you don't want to Save")) {
                  window.location.reload();
                }
              } else {
                window.location.reload();
              }
            }}
          >
            New
          </button>

          <button
            className="Save_import"
            onClick={async () => {
              if (!itemcode) {
                alert("Kindly Supply Item Code");
              } else if (!itemname) {
                alert("Kindly Supply Item Name");
              } else if (!selcategory) {
                alert("Kindly Supply Category");
              } else if (!selsubcategory) {
                alert("Kindly Supply Sub Category");
              } else if (!selclassification) {
                alert("Kindly Supply Classification");
              } else if (!selbagsize) {
                alert("Kindly Supply Size Of Bag");
              } else if (!selspecification) {
                alert("Kindly Supply Product Type");
              } else {
                await SaveItemMaster();
                alert("Saved Succesfully");
                window.location.reload();
              }
            }}
          >
            Save
          </button>

          {/* <button
            className="Save_import"
            onClick={async () => {
              if (!itemcode) {
                alert("Kindly Supply Item Code");
              } else if (!itemname) {
                alert("Kindly Supply Item Name");
              } else if (!selcategory) {
                alert("Kindly Supply Category");
              } else if (!selsubcategory) {
                alert("Kindly Supply Sub Category");
              } else if (!selclassification) {
                alert("Kindly Supply Classification");
              } else if (!selbagsize) {
                alert("Kindly Supply Size Of Bag");
              } else if (!selspecification) {
                alert("Kindly Supply Product Type");
              } else {
                await SaveItemMaster();
                alert("Updated Succesfully");
                window.location.reload();
              }
            }}
          >
            Update
          </button> */}
        </div>
      </div>
      <section className="section__Item">
        <div className="section__div">
          <div className="item_code_table">
            <div className="select_product_type">
              <div>
                <p>Select Product Type</p>
              </div>
              <div>
                <Select
                  className="pro_select_1"
                  onChange={(selectedOption) => {
                    if (selectedOption.value === "") {
                      alert("Kinly select Proper Product Type");
                    } else {
                      setselRaw("");
                      setItemcode("");
                      setItemname("");
                      setselcategory("");
                      setselsubcategory("");
                      setselclassification("");
                      setselbagsize("");
                      setselspecification("");
                      setprice("");
                      setdescription("");
                      setselhistoricproducttype(selectedOption.value);
                      getListOfItemsAfterProductType(selectedOption.value);
                    }
                  }}
                  value={
                    selhistoricproducttype
                      ? {
                          label: selhistoricproducttype,
                          value: selhistoricproducttype,
                        }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    { value: "RAW MATERIAL", label: "RAW MATERIAL" },
                    { value: "FINISHED PRODUCT", label: "FINISHED PRODUCT" },
                  ]}
                />
              </div>
            </div>
            <div className="select_item_name">
              <div>
                <p>Select Item Name</p>
              </div>

              <div>
                <Select
                  className="pro_select_1"
                  onChange={(selectedOption) => {
                    if (selectedOption.value === "") {
                      alert("Kindly Select Proper Item Name");
                    } else {
                      setfetchrow(true);
                      setselRaw(selectedOption.value);
                      getHistoric(selectedOption.value);
                    }
                  }}
                  value={
                    selRaw
                      ? {
                          label: selRaw,
                          value: selRaw,
                        }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...List.map((item) => ({
                      value: item.MAINNAME,
                      label: item.MAINNAME,
                    })),
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="item_second_line">
            <div className="item_code">
              <p>Item Code</p>

              <input
                type="text"
                className="select_code"
                value={itemcode}
                onChange={(e) => {
                  setItemcode(e.target.value);
                }}
                disabled={fetchrow == true}
              />
            </div>
            <div className="item_name">
              <p>Item Name</p>

              <input
                type="text"
                className="select_name"
                value={itemname}
                onChange={(e) => {
                  setItemname(e.target.value);
                }}
                disabled={fetchrow == true}
              />
            </div>
          </div>
          <div className="item_third_line">
            <div className="category">
              <p>Category</p>

              <div className="select__margin__master">
                <Select
                  className="pro_select_1"
                  onChange={(selectedOption) => {
                    if (selectedOption.value === "") {
                      alert("Kinly select Proper Category");
                    } else {
                      setselcategory(selectedOption.value);
                    }
                  }}
                  value={
                    selcategory
                      ? {
                          label: selcategory,
                          value: selcategory,
                        }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...category.map((item) => ({
                      value: item.CATEGORY,
                      label: item.CATEGORY,
                    })),
                  ]}
                />
              </div>
            </div>
            <div className="sub-category">
              <p>Sub Category</p>

              <Select
                className="pro_select_1"
                onChange={(selectedOption) => {
                  if (selectedOption.value === "") {
                    alert("Kinly select Proper Sub category");
                  } else {
                    setselsubcategory(selectedOption.value);
                  }
                }}
                value={
                  selsubcategory
                    ? {
                        label: selsubcategory,
                        value: selsubcategory,
                      }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...subcategory.map((item) => ({
                    value: item.CATEGORY,
                    label: item.CATEGORY,
                  })),
                ]}
              />
            </div>
          </div>
          <div className="item_fourth_line">
            <div className="classification">
              <p>Classification</p>

              <Select
                className="pro_select_1"
                onChange={(selectedOption) => {
                  if (selectedOption.value === "") {
                    alert("Kinly select Proper Classification");
                  } else {
                    setselclassification(selectedOption.value);
                  }
                }}
                value={
                  selclassification
                    ? {
                        label: selclassification,
                        value: selclassification,
                      }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...classification.map((item) => ({
                    value: item.CLASSIFICATION,
                    label: item.CLASSIFICATION,
                  })),
                ]}
              />
            </div>
            <div className="select_product_type">
              <p>Select Product Type</p>

              <Select
                className="pro_select_1"
                onChange={(selectedOption) => {
                  if (selectedOption.value === "") {
                    alert("Kinly select Proper Product Type");
                  } else {
                    setselspecification(selectedOption.value);
                  }
                }}
                value={
                  selspecification
                    ? {
                        label: selspecification,
                        value: selspecification,
                      }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  { value: "RAW MATERIAL", label: "RAW MATERIAL" },
                  { value: "FINISHED PRODUCT", label: "FINISHED PRODUCT" },
                ]}
              />
            </div>
          </div>
          <div className="item_fifth_line">
            <div className="select_size">
              <p>Select Size</p>

              <Select
                className="pro_select_1"
                onChange={(selectedOption) => {
                  if (selectedOption.value === "") {
                    alert("Kinly select Proper Size");
                  } else {
                    setselbagsize(selectedOption.value);
                  }
                }}
                value={
                  selbagsize
                    ? {
                        label: selbagsize,
                        value: selbagsize,
                      }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  { value: "24 KG", label: "24 KG" },
                  { value: "25 KG", label: "25 KG" },
                  { value: "50 KG", label: "50 KG" },
                  { value: "90 KG", label: "90 KG" },
                ]}
              />
            </div>
            <div className="standard_cost">
              <p>Standard Cost</p>

              <input
                type="text"
                className="item__code__input1"
                value={price}
                onChange={(e) => {
                  setprice(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="description">
            <p style={{ marginBottom: "50px" }}>Item Description</p>

            <textarea
              name=""
              id=""
              className="text__area__item"
              value={description}
              onChange={(e) => {
                setdescription(e.target.value);
              }}
            ></textarea>
          </div>
        </div>

        <div
          className="visible "
          style={{
            // overflow: "auto",
            height: "auto",
            marginTop: "10px",
            padding: "4px",
            zIndex: "0",
          }}
        >
          <table className="table_1">
            <thead>
              <tr className="table-header">
                <td
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "20px",
                    textAlign: "right",
                  }}
                >
                  SNO
                </td>
                <td> ITEM CODE</td>
                <td>ITEM NAME</td>
                <td>CATEGORY</td>
                <td>SUB CATEGORY</td>
                <td>PRODUCT TYPE</td>
                <td>SIZE</td>
              </tr>
            </thead>
            <tbody>
              {item.map((value, index) => (
                <tr
                  onClick={() => {
                    handleRowClick(value, index);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "20px",
                      textAlign: "right",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "30px" }}>
                    {value.ITEMCODE}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.MAINNAME}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.CATEGORY}
                  </td>
                  <td style={{ paddingLeft: "20px" }}>{value.SUBCATEGORY}</td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.OM_ITEM_FINISHED_RAW_MATERIAL == "R"
                      ? "RAW MATERIAL"
                      : "FINISHED PRODUCT" || "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.SPECIFICATIONS || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default ItemMaster;
