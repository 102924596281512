import React, { useEffect, useState } from "react";
import "../../CSS/ItemMaster.css";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import LoadingScreen from "../LoadingScreen";
import moment from "moment";
import { FaSortAmountUpAlt } from "react-icons/fa";
import { FaSortAmountDownAlt } from "react-icons/fa";
import AllNav from "../../Components/Navbar/AllNav";
import ReactApexChart from "react-apexcharts";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
const ProductionModuleDerivedItemsReport = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [item, setItem] = useState([]);
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(true);
  const [sortOrder, setSortOrder] = useState("descending");
  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const localStartOfMonth = new Date(
    startOfMonth.getTime() - startOfMonth.getTimezoneOffset() * 60000
  );

  const [startdate, setstartdate] = useState(localStartOfMonth.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());

  const filteredData = item.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.BLENDINGDATE;
    const eDate = item.BLENDINGDATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "BLENDINGNO",
        "DERIVEDITEMCODE",
        "DERIVEDDERIVEDITEMDESCRIPTION",
        "DERIVEDITEMPERC",
        "DERIVEDKG",
        "DERIVEDITEMRATE",
        "STANDARDKGBAG",
        "COSTOFSTANDARDBAG",
        "DERIVEDTOTALKG",
        "COSTPERSTANDARDKGDERIVED",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  // Sorting function based on the selected sort order
  const sortTableData = (data) => {
    if (sortOrder === "ascending") {
      return data.sort(
        (a, b) => new Date(a.BLENDINGDATE) - new Date(b.BLENDINGDATE)
      );
    } else {
      return data.sort(
        (a, b) => new Date(b.BLENDINGDATE) - new Date(a.BLENDINGDATE)
      );
    }
  };

  const sortedData = sortTableData(filteredData);

  const ExcelStockData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      "Production Module Derived Items Report"
    );
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [10, 15, 35, 15, 15, 20, 15, 20, 15, 20, 20];

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "BLENDING NO",
      "ITEM CODE",
      "ITEM NAME",
      "EXTRACT %",
      "EXTRACTED (KGS)",
      "COST AFTER EXTRACTION",
      "BAG SIZE",
      "COST OF BAG",
      "OTHER CHARGES",
      "EXTRACTION COST(OTHER CHARGES)",
      "COST OF BAG(OTHER CHARGES)",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Production_Module_Derived_Items_Report.xlsx";
      link.click();
    });
  };

  const PdfStockData = () => {
    const doc = new jsPDF();

    doc.text("Production Module Derived Items - Report", 10, 10);

    const columns = [
      "BLENDING NO",
      "ITEM CODE",
      "ITEM NAME",
      "EXTRACT %",
      "EXTRACTED (KGS)",
      "COST AFTER EXTRACTION",
      "BAG SIZE",
      "COST OF BAG",
      "OTHER CHARGES",
      "EXTRACTION COST(OTHER CHARGES)",
      "COST OF BAG(OTHER CHARGES)",
    ];
    const data = filteredData.map((stock) => [
      stock.BLENDINGNO,
      stock.DERIVEDITEMCODE,
      stock.DERIVEDDERIVEDITEMDESCRIPTION,
      stock.DERIVEDITEMPERC
        ? stock.DERIVEDITEMPERC.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "N/A",
      stock.DERIVEDKG
        ? stock.DERIVEDKG.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "N/A",
      stock.DERIVEDITEMRATE
        ? stock.DERIVEDITEMRATE.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "N/A",
      stock.COSTOFSTANDARDBAG
        ? stock.COSTOFSTANDARDBAG.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "N/A",
      stock.STANDARDKGBAG,

      stock.DERIVEDTOTALKG && stock.DERIVEDITEMRATE
        ? (stock.DERIVEDTOTALKG - stock.DERIVEDITEMRATE).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )
        : "N/A",
      stock.DERIVEDTOTALKG
        ? stock.DERIVEDTOTALKG.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "N/A",
      stock.COSTPERSTANDARDKGDERIVED
        ? stock.COSTPERSTANDARDKGDERIVED.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "N/A",
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("Production_Module_Derived_Items_Report.pdf");
  };

  useEffect(() => {
    getItemDetails();
  }, []);

  const getItemDetails = () => {
    axios
      .get(`${BASEURL}/ProductionModuleDerivedItemsReport`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        const sorted = sortTableData(response.data);
        setItem(sorted);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error(
          "Error fetching Production Module Derived Items Report:",
          error
        );
      });
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AllNav />
      <h1 style={{ textAlign: "center" }}>
        Production Module Derived Items - Report
      </h1>

      <section className="section__Item">
        <div className="section__div">
          <div className="item_code_table">
            <div className="select_product_type">
              <div>
                <p>Search</p>
              </div>
              <div>
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              <p>Start Date:</p>
              <input
                type="date"
                onChange={(e) => {
                  if (e.target.value > enddate) {
                    alert("start Date Cannot be greater than end date");
                  } else {
                    setstartdate(e.target.value);
                  }
                }}
                value={startdate.split("T")[0]}
              ></input>
              <p>End Date:</p>
              <input
                type="date"
                onChange={(e) => {
                  if (e.target.value < startdate) {
                    alert("end Date Cannot be less than start date");
                  } else {
                    setEnddate(e.target.value);
                  }
                }}
                value={enddate.split("T")[0]}
              ></input>

              <div>
                <RiFileExcel2Line onClick={ExcelStockData} size={35} />
              </div>
              <div>
                {" "}
                <BsFileEarmarkPdf onClick={PdfStockData} size={35} />
              </div>
            </div>
          </div>
        </div>

        <div className="visible">
          <table className="table_1">
            <thead>
              <tr className="table-header">
                <td>BLENDING NO</td>
                <td>ITEM CODE</td>
                <td>ITEM NAME</td>
                {/* <td>BLENDING DATE</td> */}
                <td
                  onClick={() =>
                    setSortOrder(
                      sortOrder === "ascending" ? "descending" : "ascending"
                    )
                  }
                >
                  <h4
                    className="cursor__pointer"
                    style={{ textAlign: "right", paddingRight: "20px" }}
                  >
                    BLENDING DATE &nbsp;
                    {sortOrder === "descending" ? (
                      <FaSortAmountDownAlt />
                    ) : (
                      <FaSortAmountUpAlt />
                    )}
                  </h4>
                </td>
                <td>EXTRACT %</td>
                <td> EXTRACTED (KGS)</td>
                <td>COST AFTER EXTRACTION</td>
                <td>BAG SIZE</td>
                <td>COST OF BAG</td>
                <td>OTHER CHARGES</td>
                <td> EXTRACTION COST(OTHER CHARGES)</td>
                <td>COST OF BAG(OTHER CHARGES)</td>
                <td>Markup %</td>
                <td>SELLING PRICE</td>
              </tr>
            </thead>
            <tbody>
              {sortedData.map((item, index) => (
                <tr>
                  <td style={{ textAlign: "right", paddingRight: "20px" }}>
                    {item.BLENDINGNO ? item.BLENDINGNO : "N/A"}
                  </td>
                  <td
                    style={{ textAlign: "right", paddingRight: "20px" }}
                    className="td_derived_item_code"
                  >
                    {item.DERIVEDITEMCODE ? item.DERIVEDITEMCODE : "N/A"}
                  </td>
                  <td>
                    {item.DERIVEDDERIVEDITEMDESCRIPTION
                      ? item.DERIVEDDERIVEDITEMDESCRIPTION
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "20px" }}>
                    {moment(item.BLENDINGDATE).format("DD/MM/YYYY")}
                  </td>
                  <td
                    style={{ textAlign: "right", paddingRight: "20px" }}
                    className="td_derived_item_perc"
                  >
                    {item.DERIVEDITEMPERC
                      ? item.DERIVEDITEMPERC.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td
                    style={{ textAlign: "right", paddingRight: "20px" }}
                    className="td_derived_item_kg"
                  >
                    {item.DERIVEDKG
                      ? item.DERIVEDKG.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td
                    style={{ textAlign: "right", paddingRight: "20px" }}
                    className="td_derived_item_rate"
                  >
                    {item.DERIVEDITEMRATE
                      ? item.DERIVEDITEMRATE.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td>{item.STANDARDKGBAG ? item.STANDARDKGBAG : "N/A"}</td>
                  <td style={{ textAlign: "right", paddingRight: "20px" }}>
                    {item.COSTOFSTANDARDBAG
                      ? item.COSTOFSTANDARDBAG.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "20px" }}>
                    {item.DERIVEDTOTALKG && item.DERIVEDITEMRATE
                      ? (
                          item.DERIVEDTOTALKG - item.DERIVEDITEMRATE
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td
                    style={{ textAlign: "right", paddingRight: "20px" }}
                    className="td_derived_item_total"
                  >
                    {item.DERIVEDTOTALKG
                      ? item.DERIVEDTOTALKG.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td
                    style={{ textAlign: "right", paddingRight: "20px" }}
                    className="td_cost_per_stand"
                  >
                    {item.COSTPERSTANDARDKGDERIVED
                      ? item.COSTPERSTANDARDKGDERIVED.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : "N/A"}
                  </td>

                  <td
                    style={{ textAlign: "right", paddingRight: "20px" }}
                    className="td_cost_per_stand"
                  >
                    {item.MARKUPPERC ? item.MARKUPPERC : "N/A"}
                  </td>

                  <td
                    style={{ textAlign: "right", paddingRight: "20px" }}
                    className="td_cost_per_stand"
                  >
                    {item.DERIVEDSELLINGPRICE
                      ? item.DERIVEDSELLINGPRICE.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
      <div style={{ width: "95%", margin: "0 auto", marginBottom: "400px" }}>
        {isloading ? (
          <LoadingScreen />
        ) : (
          <>
            {sortedData.length > 0 ? (
              <div>
                <div className="chart__active__buttons">
                  <button
                    className={
                      chartType === "bar"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("bar")}
                  >
                    <IoBarChartOutline />
                  </button>
                  <button
                    className={
                      chartType === "pie"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("pie")}
                  >
                    <FaChartPie />
                  </button>
                  <button
                    className={
                      chartType === "scatter"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("scatter")}
                  >
                    <BiScatterChart />
                  </button>
                  <button
                    className={
                      chartType === "area"
                        ? "active__chart "
                        : "not__active__chart  "
                    }
                    onClick={() => handleButtonClick("area")}
                  >
                    <FaChartArea />
                  </button>
                </div>
                {chartType === "bar" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar-chart",
                      },
                      xaxis: {
                        categories: sortedData.map(
                          (item) => item?.DERIVEDITEMDESCRIPTION
                        ),
                        title: {
                          text: "ITEM NAME",
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "COST OF BAG(OTHER CHARGES)",
                          },
                        },
                        {
                          title: {
                            text: "SELLING PRICE	",
                          },
                          opposite: true,
                        },
                      ],
                      dataLabels: {
                        enabled: false,
                      },
                    }}
                    series={[
                      {
                        name: "COST OF BAG(OTHER CHARGES)",
                        data: sortedData.map(
                          (item) => item?.COSTPERSTANDARDKGDERIVED
                        ),
                      },
                      {
                        name: "SELLING PRICE",

                        data: sortedData.map(
                          (item) => item?.DERIVEDSELLINGPRICE
                        ),
                      },
                    ]}
                    type="bar"
                    height={400}
                  />
                )}
                {chartType === "area" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "area-chart", // Change the ID for the area chart
                      },
                      xaxis: {
                        categories: sortedData.map(
                          (item) => item?.DERIVEDITEMDESCRIPTION
                        ),
                        title: {
                          text: "ITEM NAME", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "SELLING PRICE	", // Y-axis label
                          },
                        },
                      ],
                      dataLabels: {
                        enabled: false, // Disable the data labels for area chart
                      },
                    }}
                    series={[
                      {
                        name: "SELLING PRICE	",
                        data: sortedData.map(
                          (item) => item?.COSTPERSTANDARDKGDERIVED
                        ),
                      },
                      {
                        name: "COST OF BAG(OTHER CHARGES)	",
                        data: sortedData.map(
                          (item) => item?.DERIVEDSELLINGPRICE
                        ),
                      },
                    ]}
                    type="area" // Change chart type to "area"
                    height={300}
                  />
                )}
                {chartType === "pie" && (
                  <div className="flex__pai">
                    <aside className="for__single__pai">
                      {" "}
                      {/* Adjust the width of each pie chart */}
                      <ReactApexChart
                        options={{
                          labels: sortedData.map((item) =>
                            item?.DERIVEDITEMDESCRIPTION?.slice(0, 15)
                          ),
                          title: {
                            text: "Pie Chart of COST OF BAG(OTHER CHARGES)",
                          },
                          dataLabels: {
                            enabled: true,
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (val, opts) {
                                let index = opts.dataPointIndex;
                                let name = "COST OF BAG(OTHER CHARGES)	";
                                return name + ": " + val;
                              },
                            },
                          },
                          width: "100%", // Set width to 100% of container
                          height: "100%", // Set height to 100% of container
                        }}
                        series={sortedData.map(
                          (item) => item?.COSTPERSTANDARDKGDERIVED ?? 0
                        )}
                        type="pie"
                      />
                    </aside>
                    <aside className="for__single__pai">
                      {" "}
                      {/* Adjust the width of each pie chart */}{" "}
                      <ReactApexChart
                        options={{
                          labels: sortedData.map((item) =>
                            item?.DERIVEDITEMDESCRIPTION?.slice(0, 15)
                          ),
                          title: {
                            text: "Pie Chart for SELLING PRICE	",
                          },
                          dataLabels: {
                            enabled: true,
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (val, opts) {
                                let index = opts.dataPointIndex;
                                let name = "SELLING PRICE	";
                                return name + ": " + val;
                              },
                            },
                          },
                          width: "100%", // Set width to 100% of container
                          height: "100%", // Set height to 100% of container
                        }}
                        series={sortedData.map(
                          (item) => item?.DERIVEDSELLINGPRICE ?? 0
                        )}
                        type="pie"
                      />
                    </aside>
                  </div>
                )}

                {chartType === "scatter" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "scatter-chart",
                        zoom: {
                          enabled: false,
                        },
                      },
                      xaxis: {
                        // type: "datetime",
                        title: {
                          text: "ITEM NAME",
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "SELLING PRICE	",
                          },
                        },
                        {
                          opposite: true,
                          title: {
                            text: "COST OF BAG(OTHER CHARGES)",
                          },
                        },
                      ],
                      title: {
                        text: "Scatter Chart",
                      },
                    }}
                    series={[
                      {
                        name: "SELLING PRICE	",
                        data: sortedData.map((item) => ({
                          x: item?.DERIVEDITEMDESCRIPTION,
                          y: item?.COSTPERSTANDARDKGDERIVED,
                        })),
                      },
                      {
                        name: "COST OF BAG(OTHER CHARGES)",
                        data: sortedData.map((item) => ({
                          x: item?.DERIVEDITEMDESCRIPTION,
                          y: item?.DERIVEDSELLINGPRICE,
                        })),
                      },
                    ]}
                    type="scatter"
                    height={300}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO RESULTS FOUND
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductionModuleDerivedItemsReport;
