import React, { useEffect, useState } from "react";
import "../CSS/ItemMaster.css";
import axios from "axios";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import AllNav from "../Components/Navbar/AllNav";
import LoadingScreen from "./LoadingScreen";

const RouteMaster = () => {
  const state = useSelector((state) => state.changeTheState.user);
  const [date, setDate] = useState(new Date().toISOString());
  const username = state.emailId.split("@")[0].toUpperCase();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [item, setItem] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [citylist, setcitylist] = useState([]);
  const [selfrom, setSelfrom] = useState("");
  const [selto, setSelto] = useState("");
  const [newRouteId, setNewRouteId] = useState(null);
  const [daystotake, setDaysToTake] = useState(null);
  const [distance, setDistance] = useState(null);
  const [routename, setRouteName] = useState("");
  const [hourstotake, setHoursToTake] = useState("");
  const [islocal, setisLocal] = useState(false);
  const [selInterFrom, setSelInterFrom] = useState("");
  const [selInterTo, setSelInterTo] = useState("");
  const [interdistance, setInterDistance] = useState("");
  const [intermediate, setInterMediate] = useState([]);
  const [showInterMediate, setShowIntermediate] = useState(false);
  const [selectedRowIndex, setselectedRowIndex] = useState(null);
  const [routeend, setrouteend] = useState(false);
  const [search, setSearch] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [histintermediate, setHistIntermediate] = useState([]);
  const [hist, sethist] = useState(false);

  useEffect(() => {
    LatestRouteId();
    LoadLocation();
    FetchRoutesList();
  }, []);

  useEffect(() => {
    let totaldistance = 0;
    intermediate.forEach((item) => {
      totaldistance += parseFloat(item.InterDistance);
    });
    setDistance(totaldistance);
  }, [intermediate]);

  useEffect(() => {
    let totaldistance = 0;
    histintermediate.forEach((item) => {
      totaldistance += parseFloat(item.OM_ROUTE_DISTANCE);
    });
    setDistance(totaldistance);
  }, [histintermediate]);

  const filteredData = item.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "OM_ROUTE_ID",
        "OM_ROUTE_START",
        "OM_ROUTE_END",
        "OM_ROUTE_NAME",
        "OM_ROUTE_DISTANCE",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  const LatestRouteId = () => {
    axios
      .get(`${BASEURL}/LatestRouteId`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setNewRouteId(response.data[0].NEWROUTEID);
      })
      .catch((error) => {
        console.error("Error fetching New Route Id :", error);
      });
  };

  const FetchRoutesList = () => {
    axios
      .get(`${BASEURL}/FetchRoutesList`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setisloading(false);
        setItem(response.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Route details:", error);
      });
  };

  const LoadLocation = () => {
    axios
      .get(`${BASEURL}/LoadLocation`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setcitylist(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Location details:", error);
      });
  };

  // Adding into table ********************************************************************************
  const handleInterMediate = (value, index) => {
    setselectedRowIndex(index);
    setInterDistance(value.InterDistance);
    setSelInterFrom(value.Interfrom);
    setSelInterTo(value.InterTo);
  };

  const handleInterMediateHist = (value, index) => {
    setSelInterFrom(
      index == 0 ? selfrom : histintermediate[index - 1].OM_ROUTE_CITY_CODE
    );
    setSelInterTo(value.OM_ROUTE_CITY_CODE);
    setInterDistance(value.OM_ROUTE_DISTANCE);
  };

  const AddIntermediate = () => {
    if (!selInterFrom) {
      alert("kindly Supply the Intermediate From Location");
    } else if (!selInterTo) {
      alert("kindly Supply the Intermediate To Location");
    } else if (selInterFrom == selInterTo) {
      alert(
        "Intermediate From Location Cannnot be equal to Intermediate To Location"
      );
    } else if (Number(interdistance) <= 0) {
      alert("Kindly Supply Intermediate Distance greater than 0 ");
    } else {
      if (selInterTo == selto) {
        if (
          window.confirm(
            "You are reaching the end of Main Route , Do you want to continue ?"
          )
        ) {
          const newValue = {
            Interfrom: selInterFrom,
            InterTo: selInterTo,
            InterDistance: interdistance,
          };

          const existingRowIndex = intermediate.findIndex(
            (charge) =>
              charge.Interfrom === newValue.Interfrom &&
              charge.InterTo === newValue.InterTo
          );

          if (existingRowIndex !== -1) {
            const updatedItems = [...intermediate];
            updatedItems[existingRowIndex] = newValue;
            setInterMediate(updatedItems);
          } else {
            setInterMediate([...intermediate, newValue]);
          }

          setShowIntermediate(true);
          clearAll();
        }
      } else {
        const newValue = {
          Interfrom: selInterFrom,
          InterTo: selInterTo,
          InterDistance: interdistance,
        };

        const existingRowIndex = intermediate.findIndex(
          (charge) =>
            charge.Interfrom === newValue.Interfrom &&
            charge.InterTo === newValue.InterTo
        );

        if (existingRowIndex !== -1) {
          const updatedItems = [...intermediate];
          updatedItems[existingRowIndex] = newValue;
          setInterMediate(updatedItems);
        } else {
          setInterMediate([...intermediate, newValue]);
        }

        setShowIntermediate(true);
        clearAll();
      }
    }
  };

  const clearAll = () => {
    if (selto == selInterTo) {
      setSelInterFrom("");
      setSelInterTo("");
      setInterDistance("");
      setrouteend(true);
    } else {
      setSelInterFrom(selInterTo);
      setSelInterTo("");
      setInterDistance("");
    }
  };

  const RemoveInterMediate = () => {
    if (intermediate.length > 0) {
      const updatedValues = [...intermediate];

      if (
        selectedRowIndex !== null &&
        selectedRowIndex >= 0 &&
        selectedRowIndex < updatedValues.length
      ) {
        updatedValues.splice(selectedRowIndex, 1);
        setselectedRowIndex(null);
      } else {
        updatedValues.pop();
      }
      clearAll();
      setInterMediate(updatedValues);
      if (updatedValues.length === 0) {
        setShowIntermediate(false);
      }
    } else {
      alert("Please add Intermediate details First");
    }
  };

  const UpdateHistIntermediate = () => {
    if (!selInterFrom) {
      alert("kindly Supply the Intermediate From Location");
    } else if (!selInterTo) {
      alert("kindly Supply the Intermediate To Location");
    } else if (selInterFrom == selInterTo) {
      alert(
        "Intermediate From Location Cannnot be equal to Intermediate To Location"
      );
    } else if (Number(interdistance) <= 0) {
      alert("Kindly Supply Intermediate Distance greater than 0 ");
    } else {
      if (selInterTo == selto) {
        if (
          window.confirm(
            "You are reaching the end of Main Route , Do you want to continue ?"
          )
        ) {
          const newValue = {
            OM_ROUTE_CITY_CODE: selInterTo,
            OM_ROUTE_DISTANCE: interdistance,
          };

          const existingRowIndex = histintermediate.findIndex(
            (charge) =>
              charge.OM_ROUTE_CITY_CODE === newValue.OM_ROUTE_CITY_CODE
          );

          if (existingRowIndex !== -1) {
            const updatedItems = [...histintermediate];
            updatedItems[existingRowIndex] = newValue;
            setHistIntermediate(updatedItems);
          } else {
            setHistIntermediate([...histintermediate, newValue]);
          }

          setShowIntermediate(true);
          clearAll();
        }
      } else {
        const newValue = {
          OM_ROUTE_CITY_CODE: selInterTo,
          OM_ROUTE_DISTANCE: interdistance,
        };

        const existingRowIndex = histintermediate.findIndex(
          (charge) => charge.OM_ROUTE_CITY_CODE === newValue.OM_ROUTE_CITY_CODE
        );

        if (existingRowIndex !== -1) {
          const updatedItems = [...histintermediate];
          updatedItems[existingRowIndex] = newValue;
          setHistIntermediate(updatedItems);
        } else {
          setHistIntermediate([...histintermediate, newValue]);
        }

        setShowIntermediate(true);
        clearAll();
      }
    }
  };

  const RemoveInterMediateHist = () => {
    setrouteend(false);
    if (window.confirm("Are you sure you want to remove details")) {
      const updatedValues = [...histintermediate];
      const isExisting = histintermediate.some((charge) => {
        return (
          charge.OM_ROUTE_CITY_CODE ===
          updatedValues[updatedValues.length - 1].OM_ROUTE_CITY_CODE
        );
      });
      if (isExisting) {
        DeleteRouteMasterDetails(
          updatedValues[updatedValues.length - 1].OM_ROUTE_CITY_CODE
        );
        updatedValues.pop();
      } else {
        updatedValues.pop();
      }

      setHistIntermediate(updatedValues);
      if (updatedValues.length === 0) {
        setShowIntermediate(false);
      }
    }
    clearRemove();
  };

  const clearRemove = () => {
    setSelInterFrom(
      histintermediate[histintermediate.length - 2].OM_ROUTE_CITY_CODE
    );
    setSelInterTo("");
    setInterDistance("");
  };

  // ****************************************************************************************************
  // Saving
  async function SaveRouteMasterHeader() {
    setisloading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/InsertRouteMasterHeader`,
        {
          ROUTEID: Number(newRouteId),
          ROUTESTART: selfrom,
          ROUTEEND: selto,
          ROUTEDISTANCE: Number(distance),
          CREEATEDDATE: date,
          CREATEDBY: username,
          DAYS: Number(daystotake),
          ROUTELOCAL: islocal == true ? 1 : 0,
          ROUTENAME: routename,
          ROUTEHOURS: Number(hourstotake),
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        console.log(`Route Master Header saved successfully`);
      } else {
        console.error(`Error while saving Route Master Header`);
      }
    } catch (error) {
      console.error(`Error while saving Route Master Header`, error);
    }
  }

  async function SaveRouteMasterDetails() {
    for (let index = 0; index < intermediate.length; index++) {
      const value = intermediate[index];
      try {
        const response = await axios.post(
          `${BASEURL}/InsertRouteMasterDetails`,
          {
            ROUTEID: Number(newRouteId),
            INTERMEDIATECITYCODE: value.InterTo,
            INTERMEDIATEDISTANCE: Number(value.InterDistance),
            CREATEDBY: username,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          setisloading(false);
          console.log(`Row ${index + 1} saved successfully`);
        } else {
          setisloading(false);
          console.error(`Error while saving row ${index + 1}`);
        }
      } catch (error) {
        setisloading(false);
        console.error(`Error while saving row ${index + 1}`, error);
      }
    }
  }

  async function UpdateRouteMasterDetails() {
    for (let index = 0; index < histintermediate.length; index++) {
      const value = histintermediate[index];
      try {
        const response = await axios.post(
          `${BASEURL}/InsertRouteMasterDetails`,
          {
            ROUTEID: Number(newRouteId),
            INTERMEDIATECITYCODE: value.OM_ROUTE_CITY_CODE,
            INTERMEDIATEDISTANCE: Number(value.OM_ROUTE_DISTANCE),
            CREATEDBY: username,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          setisloading(false);
          console.log(`Row ${index + 1} Updated successfully`);
        } else {
          setisloading(false);
          console.error(`Error while Updating row ${index + 1}`);
        }
      } catch (error) {
        setisloading(false);
        console.error(`Error while Updating row ${index + 1}`, error);
      }
    }
  }

  async function DeleteRouteMasterDetails(value) {
    try {
      const response = await axios.post(
        `${BASEURL}/DeleteRouteMasterDetails`,
        {
          ROUTEID: Number(newRouteId),
          INTERMEDIATECITYCODE: value,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        console.log(`Route Master Details deleted successfully`);
      } else {
        console.error(`Error while deleting Route Master Details`);
      }
    } catch (error) {
      console.error(`Error while deleting Route Master Details`, error);
    }
  }

  const handleRowClick = (value, index) => {
    sethist(true);
    setShowIntermediate(true);
    setrouteend(false);
    setNewRouteId(value.OM_ROUTE_ID);
    setSelfrom(value.OM_ROUTE_START);
    setSelto(value.OM_ROUTE_END);
    setDistance(value.OM_ROUTE_DISTANCE);
    setDaysToTake(value.OM_ROUTE_DAYS);
    setHoursToTake(value.OM_ROUTE_HOURS);
    setRouteName(value.OM_ROUTE_NAME);
    setisLocal(value.OM_ROUTE_LOCAL == 1 ? true : false);

    axios
      .post(
        `${BASEURL}/fetchRouteDetails`,
        {
          routeid: Number(value.OM_ROUTE_ID),
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setHistIntermediate(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Intermediate Route details:", error);
      });
  };

  const ClearAllInputs = () => {
    setSelfrom("");
    setSelto("");
    setDistance("");
    setDaysToTake("");
    setHoursToTake("");
    setRouteName("");
    setisLocal(false);
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AllNav />

      <h1 style={{ textAlign: "center" }}>Route Master</h1>
      <div className="header_route">
        <div className="flex">
          <button
            className="Save_import"
            onClick={() => {
              if (selfrom || selto) {
                if (window.confirm("Are you sure you don't want to Save")) {
                  window.location.reload();
                }
              } else {
                window.location.reload();
              }
            }}
          >
            New
          </button>

          <button
            className="Save_import"
            onClick={async () => {
              if (!newRouteId) {
                alert("Kindly Supply Route Id");
              } else if (!selfrom) {
                alert("Kindly Supply From Location");
              } else if (!selto) {
                alert("Kindly Supply To Location");
              } else if (!routename) {
                alert("Kindly Supply Route Name");
              } else if (!distance) {
                alert("Kindly Supply Distance");
              } else if (!daystotake) {
                alert("Kindly Supply Days To Take");
              } else if (!hourstotake) {
                alert("Kindly Supply Hours To Take");
              } else {
                if (histintermediate.length > 0) {
                  await SaveRouteMasterHeader();
                  await UpdateRouteMasterDetails();
                  alert("Saved Succesfully");
                  window.location.reload();
                } else {
                  await SaveRouteMasterHeader();
                  await SaveRouteMasterDetails();
                  alert("Saved Succesfully");
                  window.location.reload();
                }
              }
            }}
          >
            Save
          </button>

          {/* <button
            className="Save_import"
            onClick={async () => {
              if (!newRouteId) {
                alert("Kindly Supply Route Id");
              } else if (!selfrom) {
                alert("Kindly Supply From Location");
              } else if (!selto) {
                alert("Kindly Supply To Location");
              } else if (!routename) {
                alert("Kindly Supply Route Name");
              } else if (!distance) {
                alert("Kindly Supply Distance");
              } else if (!daystotake) {
                alert("Kindly Supply Days To Take");
              } else if (!hourstotake) {
                alert("Kindly Supply Hours To Take");
              } else {
                if (histintermediate.length > 0) {
                  await SaveRouteMasterHeader();
                  await UpdateRouteMasterDetails();
                  alert("Updated Succesfully");
                  window.location.reload();
                } else {
                  alert("Kindly Click on Save button to Proceed");
                }
              }
            }}
          >
            Update
          </button> */}
        </div>
      </div>
      <section className="section__Item">
        <div className="section__div">
          <div style={{ display: "flex", marginTop: "5px" }}>
            <span style={{ fontSize: "25px", color: "red" }}>Main Route</span>
          </div>

          <div className="item_second_line">
            <div className="item_code_route">
              <p>Route Id</p>
              <input
                type="text"
                className="select_code"
                value={newRouteId}
                readOnly
              />
            </div>
            <button
              onClick={() => {
                ClearAllInputs();
                clearAll();
                setPopupOpen(true);
              }}
              style={{
                paddingLeft: " 5px",
                paddingRight: "5px",
                borderRadius: "5px",
                marginTop: "5px",
                height: "30px",
                background: "#002d62",
                color: "white",
              }}
            >
              ...
            </button>
            &nbsp;
            {isPopupOpen && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                  background: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1000,
                }}
              >
                <div
                  style={{
                    position: "fixed",
                    width: "900px",
                    height: "90vh",
                    padding: "20px",
                    background: "white",
                    borderRadius: "8px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                    zIndex: 1001,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <p>Search :</p>&nbsp;
                      <input
                        type="text"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      ></input>
                    </div>
                    <button
                      onClick={() => setPopupOpen(false)}
                      className="Save_import"
                    >
                      Close
                    </button>
                  </div>

                  <aside
                    style={{
                      overflow: "auto",
                      height: "80vh",
                      paddingTop: "10px ",
                    }}
                  >
                    <table className="table_1">
                      <thead>
                        <tr className="table-header">
                          <td>Route Id</td>
                          <td>From Location</td>
                          <td>To Location</td>
                          <td>Route Name</td>
                          <td>Distance</td>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((row, index) => (
                          <tr
                            key={index}
                            onClick={() => {
                              setPopupOpen(false);
                              handleRowClick(row, index);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{row.OM_ROUTE_ID ? row.OM_ROUTE_ID : ""}</td>
                            <td>
                              {row.OM_ROUTE_START ? row.OM_ROUTE_START : ""}
                            </td>
                            <td>{row.OM_ROUTE_END ? row.OM_ROUTE_END : ""}</td>
                            <td>
                              {row.OM_ROUTE_NAME ? row.OM_ROUTE_NAME : ""}
                            </td>
                            <td>
                              {row.OM_ROUTE_DISTANCE
                                ? row.OM_ROUTE_DISTANCE
                                : 0}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </aside>
                </div>
              </div>
            )}
            <div className="item_name">
              <p>From Location</p>
              <div className="select__margin__master">
                <Select
                  className="pro_select_1"
                  onChange={(selectedOption) => {
                    if (selectedOption.value === "") {
                      alert("Kinly select Proper Category");
                    } else {
                      setSelfrom(selectedOption.value);
                    }
                  }}
                  value={
                    selfrom
                      ? {
                          label: selfrom,
                          value: selfrom,
                        }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...citylist.map((item) => ({
                      value: item.CITYNAME,
                      label: item.CITYNAME,
                    })),
                  ]}
                  isDisabled={hist == true}
                />
              </div>
            </div>
            <div className="category_route">
              <p>To Location</p>
              <div className="select__margin__master">
                <Select
                  className="pro_select_1"
                  onChange={(selectedOption) => {
                    if (selectedOption.value === "") {
                      alert("Kinly select Proper Category");
                    } else if (!selfrom) {
                      alert("Kindly Supply From location first");
                    } else if (selectedOption.value == selfrom) {
                      alert("To location cannot be same a From location");
                    } else {
                      setSelto(selectedOption.value);
                      setSelInterTo(selectedOption.value);
                      setSelInterFrom(selfrom);
                    }
                  }}
                  value={
                    selto
                      ? {
                          label: selto,
                          value: selto,
                        }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...citylist.map((item) => ({
                      value: item.CITYNAME,
                      label: item.CITYNAME,
                    })),
                  ]}
                  isDisabled={hist == true}
                />
              </div>
            </div>
          </div>
          {/* 2nd line */}
          <div style={{ display: "flex", marginTop: "5px" }}>
            <div className="item_code_route">
              <p>Route Name</p>
              <input
                type="text"
                style={{ width: "400px", height: "30px" }}
                value={routename}
                onChange={(e) => {
                  setRouteName(e.target.value);
                }}
              />
            </div>

            <div className="item_code_route" style={{ marginLeft: "38px" }}>
              <p>Days to take</p>
              <input
                type="number"
                className="select_code"
                value={daystotake}
                onChange={(e) => {
                  setDaysToTake(e.target.value);
                }}
              />
            </div>
            <div className="item_code_route" style={{ marginLeft: "38px" }}>
              <p>Hours to take</p>
              <input
                type="number"
                className="select_code"
                value={hourstotake}
                onChange={(e) => {
                  setHoursToTake(e.target.value);
                }}
              />
            </div>

            <div className="item_code_route" style={{ marginLeft: "38px" }}>
              <input
                type="checkbox"
                checked={islocal}
                onChange={() => {
                  setisLocal(!islocal);
                }}
              />
              <p>Local</p>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "5px" }}>
            <span style={{ fontSize: "25px", color: "red" }}>
              Intermediate Routes
            </span>
          </div>
          <div style={{ display: "flex", marginTop: "5px" }}>
            <div className="item_name">
              <p>From Location</p>
              <div className="select__margin__master">
                <Select
                  className="pro_select_1"
                  onChange={(selectedOption) => {
                    if (selectedOption.value === "") {
                      alert("Kinly select Proper Category");
                    } else {
                      setSelInterFrom(selectedOption.value);
                    }
                  }}
                  value={
                    selInterFrom
                      ? {
                          label: selInterFrom,
                          value: selInterFrom,
                        }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...citylist.map((item) => ({
                      value: item.CITYNAME,
                      label: item.CITYNAME,
                    })),
                  ]}
                  isDisabled={routeend == true}
                />
              </div>
            </div>

            <div className="category_route">
              <p>To Location</p>
              <div className="select__margin__master">
                <Select
                  className="pro_select_1"
                  onChange={(selectedOption) => {
                    if (selectedOption.value === "") {
                      alert("Kinly select Proper Category");
                    } else if (selectedOption.value == selInterFrom) {
                      alert("To Location Cannot be Same as From Location");
                    } else {
                      setSelInterTo(selectedOption.value);
                    }
                  }}
                  value={
                    selInterTo
                      ? {
                          label: selInterTo,
                          value: selInterTo,
                        }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...citylist.map((item) => ({
                      value: item.CITYNAME,
                      label: item.CITYNAME,
                    })),
                  ]}
                  isDisabled={routeend == true}
                />
              </div>
            </div>

            <div className="item_code_route" style={{ marginLeft: "38px" }}>
              <p>Distance</p>
              <input
                type="number"
                className="select_code"
                value={interdistance}
                onChange={(e) => {
                  if (!routename) {
                    alert("Kindly Provide Route name");
                    setInterDistance("");
                  } else if (!daystotake) {
                    alert("Kindly Provide days to take");
                    setInterDistance("");
                  } else if (!hourstotake) {
                    alert("Kindly Provide Hours To take");
                    setInterDistance("");
                  } else {
                    setInterDistance(e.target.value);
                  }
                }}
                disabled={routeend == true}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "5px",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="Add"
              style={{ marginRight: "5px" }}
              onClick={() => {
                if (histintermediate.length > 0) {
                  UpdateHistIntermediate();
                } else {
                  if (routeend == true) {
                    alert("You have reached to the end of Main Route");
                  } else {
                    AddIntermediate();
                  }
                }
              }}
            >
              Add
            </button>
            <button
              className="Add"
              onClick={() => {
                if (histintermediate.length > 0) {
                  RemoveInterMediateHist();
                } else {
                  RemoveInterMediate();
                }
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </section>
      {showInterMediate && (
        <div
          className="visible "
          style={{
            height: "auto",
            width: "auto",
            padding: "4px",
            zIndex: "0",
          }}
        >
          <table className="table_1">
            <thead>
              <tr className="table-header">
                <td style={{ textAlign: "left" }}>From Location</td>
                <td style={{ textAlign: "left" }}>To Location</td>
                <td style={{ textAlign: "right" }}>Distance</td>
              </tr>
            </thead>
            <tbody>
              {histintermediate.length > 0
                ? histintermediate.map((value, index) => (
                    <tr
                      onClick={() => {
                        handleInterMediateHist(value, index);
                      }}
                      style={{ cursor: "pointer" }}
                      key={index}
                    >
                      <td style={{ textAlign: "left" }}>
                        {index == 0
                          ? selfrom
                          : histintermediate[index - 1].OM_ROUTE_CITY_CODE}
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {value.OM_ROUTE_CITY_CODE
                          ? value.OM_ROUTE_CITY_CODE
                          : "N/A"}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {value.OM_ROUTE_DISTANCE
                          ? value.OM_ROUTE_DISTANCE
                          : "N/A"}
                      </td>
                    </tr>
                  ))
                : intermediate.map((value, index) => (
                    <tr
                      onClick={() => {
                        handleInterMediate(value, index);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <td style={{ textAlign: "left" }}>
                        {value.Interfrom ? value.Interfrom : "N/A"}
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {value.InterTo ? value.InterTo : "N/A"}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {value.InterDistance ? value.InterDistance : "N/A"}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p>Total Distance :</p>
            <input
              type="number"
              className="select_code"
              value={distance}
              readOnly
            />
          </div>
        </div>
      )}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "5px",
        }}
      >
        <div style={{ marginRight: "10px" }}>
          <RiFileExcel2Line onClick={ExcelStockData} size={35} />
        </div>
        <div style={{ marginRight: "5px" }}>
          <BsFileEarmarkPdf onClick={PdfStockData} size={35} />
        </div>
      </div>

      <div
        className="visible "
        style={{
          height: "auto",
          padding: "4px",
          zIndex: "0",
        }}
      >
        <table className="table_1">
          <thead>
            <tr className="table-header">
              <td style={{ textAlign: "right" }}>Route Id</td>
              <td style={{ textAlign: "left" }}>From Location</td>
              <td style={{ textAlign: "left" }}>To Location</td>
              <td style={{ textAlign: "left" }}>Route Name</td>
              <td style={{ textAlign: "right" }}>Distance</td>
              <td style={{ textAlign: "right" }}>Days To take</td>
              <td style={{ textAlign: "right" }}>Hours To take</td>
            </tr>
          </thead>
          <tbody>
            {item.map((value, index) => (
              <tr
                onClick={() => {
                  handleRowClick(value, index);
                }}
                style={{ cursor: "pointer" }}
              >
                <td style={{ textAlign: "right" }}>
                  {value.OM_ROUTE_ID ? value.OM_ROUTE_ID : "N/A"}
                </td>
                <td style={{ textAlign: "left" }}>
                  {value.OM_ROUTE_START ? value.OM_ROUTE_START : "N/A"}
                </td>
                <td style={{ textAlign: "left" }}>
                  {value.OM_ROUTE_END ? value.OM_ROUTE_END : "N/A"}
                </td>
                <td style={{ textAlign: "left" }}>{value.OM_ROUTE_NAME}</td>
                <td style={{ textAlign: "right" }}>
                  {value.OM_ROUTE_DISTANCE}
                </td>
                <td style={{ textAlign: "right", paddingRight: "50px" }}>
                  {value.OM_ROUTE_DAYS}
                </td>
                <td style={{ textAlign: "right", paddingRight: "50px" }}>
                  {value.OM_ROUTE_HOURS}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
    </div>
  );
};

export default RouteMaster;
