import changeTheState from "./loginLogout";
// import changeLoginState from "./loginstatred";
import selectStatements from "./statements";
import { combineReducers } from "redux";
import costingnoReducer from "./costingnoReducer";

const rootReducers = combineReducers({
  changeTheState,
  // changeLoginState,
  selectStatements,
  costingnoReducer,
});

export default rootReducers;
