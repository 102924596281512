const initialState = "";

const costingnoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_COSTINGNO":
      return action.payload;
    default:
      return state;
  }
};

export default costingnoReducer;
