import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(
    window.sessionStorage.getItem("auth-token")
  );

  useEffect(() => {
    const token = window.sessionStorage.getItem("auth-token");
    if (token) {
      setAuthUser({ token }); // Set authUser with token if it exists
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authUser, setAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
};
