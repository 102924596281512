import React from "react";
import "../../CSS/ListofProducts.css";
import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import AllNav from "../../Components/Navbar/AllNav";
import LoadingScreen from "../LoadingScreen";
import ReactApexChart from "react-apexcharts";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
import moment from "moment";

const CostAnalysis = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [productioncost, setProductionCost] = useState("");
  const [search, setSearch] = useState("");
  const [selPricAftTra, setselPricAftTra] = useState("");
  const [myItem, setItem] = useState([]);
  const [selItemCode, setSelItemCode] = useState("");
  const [selItemName, setSelItemName] = useState("");

  const [myRouteId, setmyRouteId] = useState("");
  const [FetchData, setFetchData] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [chartType, setChartType] = useState("bar");
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const localStartOfMonth = new Date(
    startOfMonth.getTime() - startOfMonth.getTimezoneOffset() * 60000
  );

  const [startdate, setstartdate] = useState(localStartOfMonth.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());

  const sortedData = FetchData.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.BLENDINGDATE;
    const eDate = item.BLENDINGDATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "BLENDINGNO",
        "RAWMTERIALCODE",
        "RAWMATERIALDESCRIPTION",
        "RAWMATERIALRATE",
        "RAWMATERIALCOST",
        "RAWMATRERIALPERCENTAGE",
        "KBBASEDONPERC",
        "COSTOF90KGBAG",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });
  const handleButtonClick = (type) => {
    setChartType(type);
  };

  useEffect(() => {
    LoadItems();
  }, []);

  const LoadItems = () => {
    axios
      .get(`${BASEURL}/LoadItems`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setisloading(false);
        setItem(response.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Item details:", error);
      });
  };

  const fetchProductiondetailsinTransport = (productCode) => {
    axios
      .get(
        `${BASEURL}/fetchProductiondetailsinTransport?DERIVEDITEMCODE=${productCode}`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        const sortedData = response.data.sort(
          (a, b) => new Date(a.BLENDINGDATE) - new Date(b.BLENDINGDATE)
        );
        setFetchData(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching Location details:", error);
      });
  };

  console.log(sortedData);
  const handleItemName = (e) => {
    const selectedValue = e;
    const selectedOption = myItem.find((obj) => obj.MAINNAME === selectedValue);
    if (selectedValue === "") {
      alert("Please select proper Item Name");
    } else if (selectedOption === "") {
      alert("Please select proper Item Name");
    } else {
      setSelItemName(selectedValue);
      setSelItemCode(selectedOption.ITEMCODE);
      fetchProductiondetailsinTransport(selectedOption.ITEMCODE);
    }
  };

  const styleforselect = {
    control: (provided, state) => ({
      ...provided,

      borderBottom: "1px solid #ccc",
      fontSize: "16px",
    }),
    option: (provided, state) => ({
      ...provided,
    }),
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AllNav />

      <h1 style={{ textAlign: "center" }}>Report Cost Analysis</h1>

      <div>
        <section className="main__sec__Align">
          <main className=" new__first__div">
            <aside>
              <p>Product Name&nbsp;</p>
              <div className="select__name">
                <Select
                  className=""
                  onChange={(selectedOption) => {
                    if (myRouteId) {
                      window.location.reload();
                    } else {
                      handleItemName(selectedOption.value);
                    }
                  }}
                  value={
                    selItemName
                      ? { label: selItemName, value: selItemName }
                      : { label: "", value: "" }
                  }
                  name="ItemName"
                  id="itemname"
                  styles={styleforselect}
                  options={[
                    { value: "", label: "" },
                    ...myItem.map((item) => ({
                      value: item.MAINNAME,
                      label: item.MAINNAME,
                    })),
                  ]}
                />
              </div>
            </aside>
            <div className="select_product_type">
              <p>Start Date:</p>
              <input
                type="date"
                onChange={(e) => {
                  if (e.target.value > enddate) {
                    alert("start Date Cannot be greater than end date");
                  } else {
                    setstartdate(e.target.value);
                  }
                }}
                value={startdate.split("T")[0]}
              ></input>
              <p>End Date:</p>
              <input
                type="date"
                onChange={(e) => {
                  if (e.target.value < startdate) {
                    alert("end Date Cannot be less than start date");
                  } else {
                    setEnddate(e.target.value);
                  }
                }}
                value={enddate.split("T")[0]}
              ></input>
            </div>
          </main>
        </section>
      </div>
      <div className="visible for__table__length">
        <table className="table_1">
          <thead>
            <tr className="table-header">
              <td>Product Name</td>
              <td>Production Cost/Bale</td>
              <td>Selling Price Before Transport</td>

              <td>% profit</td>
              <td>BLENDING DATE</td>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((value, index) => (
              <tr>
                <td style={{ textAlign: "left", paddingRight: "50px" }}>
                  {value.DERIVEDITEMDESCRIPTION
                    ? value.DERIVEDITEMDESCRIPTION
                    : "N/A"}
                </td>{" "}
                <td style={{ textAlign: "right", paddingRight: "22px" }}>
                  {value.DERIVEDTOTALKG ? value.DERIVEDTOTALKG : "N/A"}
                </td>
                <td style={{ textAlign: "right", paddingRight: "44px" }}>
                  {value.DERIVEDSELLINGPRICE
                    ? value.DERIVEDSELLINGPRICE
                    : "N/A"}
                </td>
                <td style={{ textAlign: "right", paddingRight: "44px" }}>
                  {value?.DERIVEDSELLINGPRICE
                    ? (
                        ((Number(value?.DERIVEDSELLINGPRICE) -
                          Number(value?.DERIVEDTOTALKG)) /
                          Number(value?.DERIVEDTOTALKG)) *
                        100
                      ).toFixed(2)
                    : "N/A"}
                </td>
                <td style={{ textAlign: "right", paddingRight: "32px" }}>
                  {moment(value.BLENDINGDATE).format("DD/MM/YYYY")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ width: "95%", margin: "0 auto", marginBottom: "400px" }}>
        {isloading ? (
          <LoadingScreen />
        ) : (
          <>
            {sortedData?.length > 0 ? (
              <div>
                <div className="chart__active__buttons">
                  <button
                    className={
                      chartType === "bar"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("bar")}
                  >
                    <IoBarChartOutline />
                  </button>
                  <button
                    className={
                      chartType === "pie"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("pie")}
                  >
                    <FaChartPie />
                  </button>
                  <button
                    className={
                      chartType === "scatter"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("scatter")}
                  >
                    <BiScatterChart />
                  </button>
                  <button
                    className={
                      chartType === "area"
                        ? "active__chart "
                        : "not__active__chart  "
                    }
                    onClick={() => handleButtonClick("area")}
                  >
                    <FaChartArea />
                  </button>
                </div>
                {chartType === "bar" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar-chart",
                      },
                      xaxis: {
                        categories: sortedData.map((item) =>
                          moment(item?.BLENDINGDATE).format("DD MMM YYYY")
                        ),
                        title: {
                          text: "Month", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "COST",
                          },
                        },
                      ],
                      dataLabels: {
                        enabled: false,
                      },
                      tooltip: {
                        y: {
                          formatter: (
                            value,
                            { seriesIndex, dataPointIndex, w }
                          ) => {
                            const productionCost =
                              sortedData[dataPointIndex]?.DERIVEDTOTALKG;
                            const sellingPrice =
                              sortedData[dataPointIndex]?.DERIVEDSELLINGPRICE;
                            const percentageProfit = (
                              ((sellingPrice - productionCost) / sellingPrice) *
                              100
                            ).toFixed(2);
                            return `${value} (${percentageProfit}% profit)`;
                          },
                        },
                      },
                    }}
                    series={[
                      {
                        name: "Production Cost/Bale",
                        data: sortedData.map((item) => item?.DERIVEDTOTALKG),
                      },
                      {
                        name: "Selling Price Before Transport",
                        data: sortedData.map(
                          (item) => item?.DERIVEDSELLINGPRICE
                        ),
                      },
                    ]}
                    type="bar"
                    height={400}
                  />
                )}

                {chartType === "area" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "area-chart", // Change the ID for the area chart
                      },
                      xaxis: {
                        categories: sortedData.map((item) =>
                          moment(item?.BLENDINGDATE).format("DD MMM YYYY")
                        ),
                        title: {
                          text: "Month", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "COST", // Y-axis label
                          },
                        },
                      ],
                      dataLabels: {
                        enabled: false, // Disable the data labels for area chart
                      },
                      tooltip: {
                        y: {
                          formatter: (
                            value,
                            { seriesIndex, dataPointIndex, w }
                          ) => {
                            const productionCost =
                              sortedData[dataPointIndex]?.DERIVEDTOTALKG;
                            const sellingPrice =
                              sortedData[dataPointIndex]?.DERIVEDSELLINGPRICE;
                            const percentageProfit = (
                              ((sellingPrice - productionCost) / sellingPrice) *
                              100
                            ).toFixed(2);
                            return `${value} (${percentageProfit}% profit)`;
                          },
                        },
                      },
                    }}
                    series={[
                      {
                        name: "Production Cost/Bale		",
                        data: sortedData.map((item) => item?.DERIVEDTOTALKG),
                      },
                      {
                        name: "DERIVEDSELLINGPRICE",
                        data: sortedData.map(
                          (item) => item?.DERIVEDSELLINGPRICE
                        ),
                      },
                    ]}
                    type="area" // Change chart type to "area"
                    height={300}
                  />
                )}
                {chartType === "pie" && (
                  <div className="flex__pai">
                    <aside className="for__single__pai">
                      {" "}
                      {/* Adjust the width of each pie chart */}
                      <ReactApexChart
                        options={{
                          labels: sortedData.map((item) =>
                            moment(item?.BLENDINGDATE).format(" MMM YYYY")
                          ),
                          title: {
                            text: "Pie Chart of Production Cost/Bale	",
                          },
                          dataLabels: {
                            enabled: true,
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (val, opts) {
                                let index = opts.dataPointIndex;
                                let name = "Production Cost/Bale		";
                                return name + ": " + val;
                              },
                            },
                          },

                          width: "100%", // Set width to 100% of container
                          height: "100%", // Set height to 100% of container
                        }}
                        series={sortedData.map(
                          (item) => item?.DERIVEDTOTALKG ?? 0
                        )}
                        type="pie"
                      />
                    </aside>
                    <aside className="for__single__pai">
                      {" "}
                      {/* Adjust the width of each pie chart */}{" "}
                      <ReactApexChart
                        options={{
                          labels: sortedData.map((item) =>
                            moment(item?.BLENDINGDATE).format(" MMM YYYY")
                          ),
                          title: {
                            text: "Pie Chart for Selling Price Before Transport",
                          },
                          dataLabels: {
                            enabled: true,
                          },
                          tooltip: {
                            y: {
                              formatter: (
                                value,
                                { seriesIndex, dataPointIndex, w }
                              ) => {
                                const productionCost =
                                  sortedData[dataPointIndex]?.DERIVEDTOTALKG;
                                const sellingPrice =
                                  sortedData[dataPointIndex]
                                    ?.DERIVEDSELLINGPRICE;
                                const percentageProfit = (
                                  ((sellingPrice - productionCost) /
                                    sellingPrice) *
                                  100
                                ).toFixed(2);
                                return `${value} (${percentageProfit}% profit)`;
                              },
                            },
                          },
                          width: "100%", // Set width to 100% of container
                          height: "100%", // Set height to 100% of container
                        }}
                        series={sortedData.map(
                          (item) => item?.DERIVEDSELLINGPRICE ?? 0
                        )}
                        type="pie"
                      />
                    </aside>
                  </div>
                )}

                {chartType === "scatter" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "scatter-chart",
                        zoom: {
                          enabled: false,
                        },
                      },
                      xaxis: {
                        categories: sortedData.map((item) =>
                          moment(item?.BLENDINGDATE).format("DD MMM YYYY")
                        ),
                        title: {
                          text: "Month", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "COST",
                          },
                        },
                      ],
                      title: {
                        text: "Scatter Chart",
                      },
                      //graph

                      tooltip: {
                        y: {
                          formatter: (
                            value,
                            { seriesIndex, dataPointIndex, w }
                          ) => {
                            const productionCost =
                              sortedData[dataPointIndex]?.DERIVEDTOTALKG;
                            const sellingPrice =
                              sortedData[dataPointIndex]?.DERIVEDSELLINGPRICE;
                            const percentageProfit = (
                              ((sellingPrice - productionCost) / sellingPrice) *
                              100
                            ).toFixed(2);
                            return `${value} (${percentageProfit}% profit)`;
                          },
                        },
                      },
                    }}
                    series={[
                      {
                        name: "DERIVEDSELLINGPRICE",
                        data: sortedData.map((item) => ({
                          x: item?.DERIVEDITEMDESCRIPTION,
                          y: item?.DERIVEDSELLINGPRICE,
                        })),
                      },
                      {
                        name: "Production Cost/Bale	",
                        data: sortedData.map((item) => ({
                          x: item?.DERIVEDITEMDESCRIPTION,
                          y: item?.DERIVEDTOTALKG,
                        })),
                      },
                    ]}
                    type="scatter"
                    height={300}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO RESULTS FOUND
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default CostAnalysis;
