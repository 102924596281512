import React from "react";
import "../CSS/Forgotpwd.css";
import { useNavigate } from "react-router-dom";

function Forgotpwd() {
  const navigate = useNavigate();
  return (
    <div className="not_found">
      <p>Please contact your administrator!</p>

      <button
        className="bk"
        onClick={() => {
          navigate("/");
        }}
      >
        Back
      </button>
    </div>
  );
}

export default Forgotpwd;
