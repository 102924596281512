import React, { useEffect, useState, useContext } from "react";
import "../CSS/LoginPage.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logIn, logOut } from "../Redux/Action/index";
import Axios from "axios";
import { logInStatus } from "../Redux/Action/loginstatus";
import LoadingScreen from "./LoadingScreen";
import costingImg from "../image/agriAll1.PNG";
import Footer from "./Footer";
// import Navbar from "../../Components/Navbar/ClientNavbarLogin";
import { AuthContext } from "../context/AuthContext";

const LoginPage = () => {
  const { setAuthUser } = useContext(AuthContext);

  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [serverError, setServerError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function clearState() {
    dispatch(logIn([]));
    dispatch(logInStatus("FALSE"));
  }

  useEffect(() => {
    clearState();
  }, []);

  const handleClick = async () => {
    setIsLoading(true);
    setServerError(""); // Clear any previous error

    if (!/\S+@\S+\.\S+/.test(email)) {
      alert("Invalid email format");
      setIsLoading(false);
      return;
    }

    try {
      const response = await Axios.post(`${BASEURL}/userlogin`, {
        userDetails: { email: email, password: password },
      });

      if (response.data.message) {
        setServerError(response.data.message);
        dispatch(logIn([])); // Clear previous login data
        dispatch(logInStatus("FALSE"));
      } else {
        dispatch(logIn(response.data));
        dispatch(logInStatus("TRUE"));
        window.sessionStorage.setItem("auth-token", response?.data?.authToken);
        setAuthUser(response.data.authToken);

        console.log("auth-token", response?.data?.authToken);
        navigate("/HomePage");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setServerError("Wrong username or password");
        } else if (error.response.status === 501) {
          setServerError("Internal server error");
        }
      } else {
        setServerError(
          "No response from server or unmatched email or password"
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const renderList = (
    <div>
      {/* <Navbar /> */}
      <div className="for__amin__login__size">
        {/* company name  */}
        {/* login page heading  */}
        <div className="header">
          <picture className="pic__name">
            <p className="company__name__top text-[25px]">MarsTrack COSTING</p>
            <img
              className="image__style"
              src={costingImg}
              alt=""
              width="500"
              height="300"
            />
            <h1 className="company__name__bottom">Marstrack COSTING</h1>
          </picture>
          {/* form for admin login starts here  */}
          <div className="form_test">
            <main className="mainpage">
              <p
                style={{
                  color:
                    serverError === "Server is connected" ? "green" : "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {serverError}
              </p>
              <h2 className="headeruselogin text-[20px]">Login Page</h2>{" "}
              <div className="form_group">
                {/* error messege when user enters wrong data  */}
                <p className="loginerror">
                  <span className="skew-shake-x">{serverError}</span>
                </p>
                {/* email address is taken here as input  */}
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  className={`form_control input`}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                {/* a secure message for user so that they can enter their e-mail securely */}
                <small id="emailHelp" className="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
              <div className="form_group">
                {/* password is taken here as input  */}
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  type={passwordShown ? "text" : "password"}
                  className="form_control input"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <aside className="aside__forgot__and__Show">
                  {/* Forgot Password option is given so that the user can reset the password  */}
                  <div className="">
                    <Link to="/ForgotPwd">
                      <span className="forgotpass">forgot password</span>
                    </Link>
                  </div>
                  <div className="checkbox__show__password">
                    <p className="show_p_para"> Show Password</p>
                    <input
                      className="checkboxninput"
                      type="checkbox"
                      onClick={togglePassword}
                      checked={passwordShown}
                    />
                  </div>
                </aside>
              </div>
              {/* login button if clicked it will redirect driver to dashboard page */}
              <div className="loginpagebtn">
                <button
                  disabled={!email || !password} // Disable if email or password is empty
                  onClick={handleClick}
                  type="submit"
                  className="submitbutton1"
                >
                  Login
                </button>
              </div>
            </main>
          </div>
        </div>
      </div>
      <div className="footer__login__page">
        <Footer />
      </div>
    </div>
  );

  return <div>{isLoading ? <LoadingScreen /> : renderList}</div>;
};

export default LoginPage;
