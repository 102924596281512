import React, { useEffect, useState } from "react";
import "../CSS/Transport.css";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AllNav from "../Components/Navbar/AllNav";
import moment from "moment";
import LoadinScreen from "./LoadingScreen";

const Transport = () => {
  const state = useSelector((state) => state.changeTheState.user);
  const username = state.emailId;
  const [costingno, setCostingNo] = useState("");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [selItemCode, setselItemCode] = useState("");
  const [selItemName, setselItemName] = useState("");
  const [selSize, setselSize] = useState("");
  const [costprice, setCostprice] = useState("");
  const [selQuantity, setselQuantity] = useState("");
  const [transportcharges, setTransportCharges] = useState([]);
  const [seltransportcharges, setSeltransportcharges] = useState("");
  const [charges, setCharges] = useState("");
  const [chargesquantity, setchargesquantity] = useState("");
  const [fixedcharges, setFixedCharges] = useState("");
  const [route, setRoute] = useState([]);
  const [route1, setRoute1] = useState([]);
  const [selroute, setSelroute] = useState(null);
  const [FromLocation, setFromLocation] = useState("");
  const [ToLocation, setToLocation] = useState("");
  const [markup, setMarkup] = useState("");
  const [sellingprice, setSellingPrice] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [tabledata, setTableData] = useState([]);
  let finalcost = 0;
  let finalcost1 = 0;
  let totalnoofbags = 0;
  let totalNoOfBagsHist = 0;
  const [myRouteId, setmyRouteId] = useState("");
  const [date, setDate] = useState(new Date().toISOString());
  const [updateddata, setUpdateddata] = useState([]);
  const [index, setIndex] = useState(null);
  const [flag, setflag] = useState(0);
  const [data, setData] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(true);
  const [headeertable, setheadertable] = useState([]);
  const [showheader, setShowheader] = useState(false);
  const [selectedRowIndex, setselectedRowIndex] = useState(null);
  const [histheader, sethistheader] = useState([]);
  const [selhistrow, setselhistrow] = useState(null);
  const [histtransport, sethisttransport] = useState([]);
  const [transindex, settransindex] = useState(null);
  const [transflag, setTransflag] = useState(0);

  useEffect(() => {
    getTransportCharges();
    getRoute();
    getRoute1();
    fetchProductiondetailsinTransport();
  }, []);

  tabledata.forEach((item) => {
    finalcost += parseFloat(item.totalcharge);
  });

  histtransport.forEach((item) => {
    finalcost += parseFloat(item.TOTALCOST);
  });

  updateddata.forEach((item) => {
    finalcost1 += parseFloat(item.TOTALCOST);
  });

  headeertable.forEach((item) => {
    totalnoofbags += parseFloat(item.noofbags);
  });

  histheader.forEach((item) => {
    totalNoOfBagsHist += parseFloat(item.BAGS);
  });

  useEffect(() => {
    if (finalcost1 > 0) {
      const updated = histheader.map((item) => ({
        ...item,
        COSTPOSTPRODUCTIONANDTRANSPORT: parseFloat(
          parseFloat(finalcost1) / parseFloat(totalNoOfBagsHist) +
            parseFloat(item.COSTPOSTPRODUCTION)
        ),
        ITEMSELLINGPRICEAFTERTRANSPORT: parseFloat(
          parseFloat(finalcost1) / parseFloat(totalNoOfBagsHist) +
            parseFloat(item.ITEMSELLINGPRICEBEDFORETRANSPORT)
        ),
        TRANSPORTCOSTPERBAG: parseFloat(
          parseFloat(finalcost1) / parseFloat(totalNoOfBagsHist)
        ),
      }));
      sethistheader(updated);
    }
  }, [finalcost1]);

  useEffect(() => {
    if (finalcost > 0) {
      const updatedTableData = headeertable.map((item) => ({
        ...item,
        costofbagaftertransport: parseFloat(
          parseFloat(finalcost) / parseFloat(totalnoofbags) +
            parseFloat(item.costbftransport)
        ),
        sellingPriceaftertransport: parseFloat(
          parseFloat(finalcost) / parseFloat(totalnoofbags) +
            parseFloat(item.spbftransport)
        ),
      }));
      setheadertable(updatedTableData);
    }
  }, [finalcost]);

  const filteredData = data.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["BLENDINGNO", "DERIVEDITEMCODE", "DERIVEDITEMDESCRIPTION"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    return textMatch;
  });

  const fetchProductiondetailsinTransport = () => {
    axios
      .get(`${BASEURL}/fetchProductiondetailsinTransport`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setData(response.data);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Transport Charges:", error);
      });
  };

  const getTransportCharges = () => {
    axios
      .get(`${BASEURL}/ListOfTransportCharges`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setTransportCharges(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Transport Charges:", error);
      });
  };

  const getRoute = () => {
    axios
      .get(`${BASEURL}/ListOfRoutes`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setRoute(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Routes:", error);
      });
  };

  const getRoute1 = () => {
    axios
      .get(`${BASEURL}/ListOfRoutes1`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setRoute1(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Routes:", error);
      });
  };

  const handleSelectionProd = (row) => {
    setCostingNo(row.BLENDINGNO);
    axios
      .post(
        `${BASEURL}/FetchHeaderDetails`,
        {
          costingno: row.BLENDINGNO,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        if (response.data.length > 0) {
          setShowheader(true);
          sethistheader(response.data);
          axios
            .post(
              `${BASEURL}/FetchTransportDetails`,
              {
                costingno: row.BLENDINGNO,
              },
              {
                headers: {
                  "auth-token": authToken,
                },
              }
            )
            .then((response1) => {
              setShowTable(true);
              sethisttransport(response1.data);
            })
            .catch((error) => {
              console.error("Error fetching finished product details:", error);
            });
        } else {
          if (headeertable.length > 0) {
            if (headeertable[0].costing != row.BLENDINGNO) {
              alert("Kindly choose the same Production No");
            } else {
              setselItemName(row.DERIVEDITEMDESCRIPTION);
              setselItemCode(row.DERIVEDITEMCODE);
              setselSize(row.DERIVEDITEMSPECS);
              setselQuantity(row.DERIVEDITEMQTY);
              setCostprice(row.COSTPERSTANDARDKGDERIVED);
              setMarkup(row.MARKUPPERC);
              setSellingPrice(row.DERIVEDSELLINGPRICE);
            }
          } else {
            setselItemName(row.DERIVEDITEMDESCRIPTION);
            setselItemCode(row.DERIVEDITEMCODE);
            setselSize(row.DERIVEDITEMSPECS);
            setselQuantity(row.DERIVEDITEMQTY);
            setCostprice(row.COSTPERSTANDARDKGDERIVED);
            setMarkup(row.MARKUPPERC);
            setSellingPrice(row.DERIVEDSELLINGPRICE);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching finished product details:", error);
      });
  };

  const handleOptionChange = (selectedOption) => {
    const selectedCharges = transportcharges.find(
      (item) => item.NONSTOCKITEM === selectedOption.value
    );
    if (selectedCharges) {
      setCharges(selectedCharges.CHARGE);
      setchargesquantity(1);
      if (selectedCharges.FIXEDVARIABLE === false) {
        setFixedCharges("V");
      } else {
        setFixedCharges("F");
      }
    } else {
      setCharges("");
    }
  };

  const handleHeaders = (value, index) => {
    setselectedRowIndex(index);
    setCostingNo(value.costing);
    setselItemName(value.productname);
    setselItemCode(value.productcode);
    setselSize(value.sizeofbag);
    setselQuantity(value.noofbags);
    setCostprice(value.costbftransport);
    setMarkup(value.markup);
    setSellingPrice(value.spbftransport);
  };

  const handlehistheaders = (value, index) => {
    setselhistrow(index);
    setCostingNo(value.COSTINGSHEETNO);
    setselItemName(value.ITEMNAME);
    setselItemCode(value.ITEMCODE);
    setselSize(value.SIZEOFBAG);
    setselQuantity(value.BAGS);
    setCostprice(value.COSTPOSTPRODUCTION);
    setMarkup(value.MARKUPPERC);
    setSellingPrice(value.ITEMSELLINGPRICEBEDFORETRANSPORT);
  };

  const AddHeaders = () => {
    if (tabledata.length <= 0) {
      if (!costingno) {
        alert("kindly Supply the costing Number");
      } else if (Number(selQuantity) <= 0) {
        alert("Kindly Supply Number of bags greater than 0 ");
      } else {
        const newValue = {
          costing: costingno,
          productcode: selItemCode,
          productname: selItemName,
          noofbags: selQuantity,
          sizeofbag: selSize,
          costbftransport: costprice,
          markup: markup,
          spbftransport: sellingprice,
        };

        const existingRowIndex = headeertable.findIndex(
          (charge) =>
            charge.costing === newValue.costing &&
            charge.productcode === newValue.productcode
        );

        if (existingRowIndex !== -1) {
          const updatedItems = [...headeertable];
          updatedItems[existingRowIndex] = newValue;
          setheadertable(updatedItems);
        } else {
          setheadertable([...headeertable, newValue]);
        }

        setShowheader(true);
        clearAll();
      }
    } else {
      alert("Kindly Remove the transport charges first to add !!");
    }
  };

  const clearAll = () => {
    setselItemCode("");
    setselItemName("");
    setselQuantity("");
    setselSize("");
    setCostprice("");
    setMarkup("");
    setSellingPrice("");
  };

  const RemoveHeader = () => {
    if (headeertable.length > 0) {
      const updatedValues = [...headeertable];

      if (
        selectedRowIndex !== null &&
        selectedRowIndex >= 0 &&
        selectedRowIndex < updatedValues.length
      ) {
        updatedValues.splice(selectedRowIndex, 1);
        setselectedRowIndex(null);
      } else {
        updatedValues.pop();
      }
      clearAll();
      setheadertable(updatedValues);
      if (updatedValues.length === 0) {
        setShowheader(false);
      }
    } else {
      alert("Please add Header detailsd First");
    }
  };

  // Updating the Historic
  const UpdateHistHeaders = () => {
    var costofbagaftertransport = 0;
    var sellingPriceaftertransport = 0;
    var transportcharges = 0;

    costofbagaftertransport = parseFloat(
      parseFloat(finalcost) / parseFloat(totalNoOfBagsHist) +
        parseFloat(costprice)
    );
    sellingPriceaftertransport = parseFloat(
      parseFloat(finalcost) / parseFloat(totalNoOfBagsHist) +
        parseFloat(sellingprice)
    );

    transportcharges = parseFloat(finalcost) / parseFloat(totalNoOfBagsHist);

    if (!costingno) {
      alert("kindly Supply the costing Number");
    } else if (Number(selQuantity) <= 0) {
      alert("Kindly Supply Number of bags greater than 0 ");
    } else {
      const updatedValues = [...histheader];
      updatedValues[selhistrow] = {
        COSTINGSHEETNO: costingno,
        ITEMCODE: selItemCode,
        ITEMNAME: selItemName,
        BAGS: selQuantity,
        SIZEOFBAG: selSize,
        COSTPOSTPRODUCTION: costprice,
        MARKUPPERC: markup,
        ITEMSELLINGPRICEBEDFORETRANSPORT: sellingprice,
        COSTPOSTPRODUCTIONANDTRANSPORT: costofbagaftertransport,
        ITEMSELLINGPRICEAFTERTRANSPORT: sellingPriceaftertransport,
        TRANSPORTCOSTPERBAG: transportcharges,
      };
      sethistheader(updatedValues);
      setShowheader(true);
      clearAll();
    }
  };

  const RemoveHeaderHistoric = () => {
    if (window.confirm("Are you sure you want to remove Header details")) {
      const updatedValues = [...histheader];

      if (
        selhistrow !== null &&
        selhistrow >= 0 &&
        selhistrow < updatedValues.length
      ) {
        const isExisting = histheader.some((charge) => {
          return charge.ITEMCODE === updatedValues[selhistrow].ITEMCODE;
        });

        if (isExisting) {
          DeleteFinishedProduct(updatedValues[selhistrow].ITEMCODE);
          updatedValues.splice(selhistrow, 1);
          setselhistrow(null);
        } else {
          updatedValues.splice(selhistrow, 1);
          setselhistrow(null);
        }
      } else {
        const isExisting = histheader.some((charge) => {
          return (
            charge.ITEMCODE === updatedValues[updatedValues.length - 1].ITEMCODE
          );
        });
        if (isExisting) {
          DeleteFinishedProduct(
            updatedValues[updatedValues.length - 1].ITEMCODE
          );
          updatedValues.pop();
        } else {
          updatedValues.pop();
        }
      }

      sethistheader(updatedValues);
      if (updatedValues.length === 0) {
        setShowheader(false);
      }
    }
    clearAll();
  };

  const UpdateCalculations = () => {
    const updated = histheader.map((item) => ({
      ...item,
      COSTPOSTPRODUCTIONANDTRANSPORT: parseFloat(
        parseFloat(item.TRANSPORTCOSTPERBAG) +
          parseFloat(item.COSTPOSTPRODUCTION)
      ),
      ITEMSELLINGPRICEAFTERTRANSPORT: parseFloat(
        parseFloat(item.TRANSPORTCOSTPERBAG) +
          parseFloat(item.ITEMSELLINGPRICEBEDFORETRANSPORT)
      ),
    }));
    sethistheader(updated);
  };

  // Transport Charges -------------------------------------------------------------------------

  const AddTransportCharges = () => {
    if (transflag === 1) {
      let updatedvalue = [...tabledata];
      updatedvalue[transindex] = {
        costing: costingno,
        transportname: seltransportcharges,
        charge: charges,
        routee: selroute,
        from: FromLocation,
        to: ToLocation,
        totalcharge: totalnoofbags * charges,
        fixedcharge: fixedcharges,
      };
      setTableData(updatedvalue);
      setShowTable(true);
      clearallinputs();
      setTransflag(0);
    } else {
      const newValue = {
        costing: costingno,
        transportname: seltransportcharges,
        charge: charges,
        routee: selroute,
        from: FromLocation,
        to: ToLocation,
        totalcharge: totalnoofbags * charges,
        fixedcharge: fixedcharges,
      };

      const isDuplicateRow = tabledata.some((charge) => {
        return (
          charge.transportname === newValue.transportname &&
          charge.routee === newValue.routee
        );
      });

      if (isDuplicateRow) {
        alert("This Charge has been added previusly.");
      } else {
        setTableData([...tabledata, newValue]);
      }

      setShowTable(true);
      clearallinputs();
    }
  };

  // Add New Transport
  const addnewTransport = () => {
    const newValue = {
      costing: costingno,
      TRANSPORTCHARGETYPE: seltransportcharges,
      SERVICERATE: charges,
      ROUTEID: selroute,
      FROMLOCATION: FromLocation,
      TOLOCATION: ToLocation,
      TOTALCOST: totalNoOfBagsHist * charges,
      FIXEDORVARIABLE: fixedcharges == 0 ? "V" : "F",
    };

    const isDuplicateRow = histtransport.some((charge) => {
      return (
        charge.TRANSPORTCHARGETYPE === newValue.TRANSPORTCHARGETYPE &&
        charge.ROUTEID === newValue.ROUTEID
      );
    });

    const isDuplicateRow1 = updateddata.some((charge) => {
      return (
        charge.TRANSPORTCHARGETYPE === newValue.TRANSPORTCHARGETYPE &&
        charge.ROUTEID === newValue.ROUTEID
      );
    });

    if (isDuplicateRow || isDuplicateRow1) {
      alert("This Transport Details has been added previusly.");
    } else {
      if (updateddata.length > 0) {
        setUpdateddata([...updateddata, newValue]);
      } else {
        setUpdateddata([...histtransport, newValue]);
      }
    }

    setShowTable(true);
    clearallinputs();
  };

  // Modifying Transport Details
  const handleModify = () => {
    let updatedValues = [];
    if (updateddata.length > 0) {
      updatedValues = [...updateddata];
    } else {
      updatedValues = [...histtransport];
    }

    updatedValues[index] = {
      costing: costingno,
      TRANSPORTCHARGETYPE: seltransportcharges,
      SERVICERATE: charges,
      ROUTEID: selroute,
      FROMLOCATION: FromLocation,
      TOLOCATION: ToLocation,
      TOTALCOST: totalNoOfBagsHist * charges,
      FIXEDORVARIABLE: fixedcharges == 0 ? "V" : "F",
    };

    setUpdateddata(updatedValues);

    setShowTable(true);
    clearallinputs();
  };

  const clearallinputs = () => {
    setSeltransportcharges("");
    setchargesquantity("");
    setCharges("");
    setFromLocation("");
    setToLocation("");
    setSelroute(null);
    setFromLocation("");
    setToLocation("");
    setmyRouteId("");
    setFixedCharges("");
  };

  const RemoveTransport = () => {
    if (tabledata.length > 0) {
      const updatedValues = [...tabledata];
      const totalcharge = tabledata[tabledata.length - 1].totalcharge;
      const updatedTableData = headeertable.map((item) => ({
        ...item,
        costofbagaftertransport:
          parseFloat(item.costofbagaftertransport) - parseFloat(totalcharge),
        sellingPriceaftertransport:
          parseFloat(item.sellingPriceaftertransport) - parseFloat(totalcharge),
      }));
      setheadertable(updatedTableData);
      updatedValues.pop();
      setTableData(updatedValues);
      if (updatedValues.length === 0) {
        const updatedTableData = headeertable.map((item) => ({
          ...item,
          costofbagaftertransport: 0,
          sellingPriceaftertransport: 0,
        }));
        setheadertable(updatedTableData);
        setShowTable(false);
      }
    } else {
      alert("Please add Transport Charges First");
    }
  };

  const RemoveTransportHistoric = () => {
    if (window.confirm("Are you sure you want to remove Transport charges")) {
      let updatedValues = [];
      if (updateddata.length > 0) {
        updatedValues = [...updateddata];
      } else {
        updatedValues = [...histtransport];
      }

      if (index !== null && index >= 0 && index < updatedValues.length) {
        const isExisting = histtransport.some((charge) => {
          return (
            charge.TRANSPORTCHARGETYPE ===
              updatedValues[index].TRANSPORTCHARGETYPE &&
            charge.ROUTEID === updatedValues[index].ROUTEID
          );
        });

        if (isExisting) {
          DeleteTransport(
            updatedValues[index].TRANSPORTCHARGETYPE,
            updatedValues[index].ROUTEID
          );
          updatedValues.splice(index, 1);
          setIndex(null);
        } else {
          updatedValues.splice(index, 1);
          setIndex(null);
        }
      } else {
        const isExisting = histtransport.some((charge) => {
          return (
            charge.TRANSPORTCHARGETYPE ===
              updatedValues[updatedValues.length - 1].TRANSPORTCHARGETYPE &&
            charge.ROUTEID === updatedValues[updatedValues.length - 1].ROUTEID
          );
        });
        if (isExisting) {
          DeleteTransport(
            updatedValues[updatedValues.length - 1].TRANSPORTCHARGETYPE,
            updatedValues[updatedValues.length - 1].ROUTEID
          );
          updatedValues.pop();
        } else {
          updatedValues.pop();
        }
      }

      setUpdateddata(updatedValues);

      if (updatedValues.length === 0) {
        setShowTable(false);
      }
      if (showTable === false) {
        alert("Please add Raw Material First");
      }
    }
    clearallinputs();
  };

  const getRouteId = async (e) => {
    if (FromLocation === "") {
      alert("Please select the from location first");
    } else {
      const loadRouteId = await axios.post(
        `${BASEURL}/LoadRoutes`,
        {
          fromloc: FromLocation,
          toloc: e,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      if (loadRouteId.data.err) {
        alert(loadRouteId.data.err);
        setFromLocation("");
        setToLocation("");
        setmyRouteId("");
      } else {
        setmyRouteId(loadRouteId.data[0].ROUTEID);
        setSelroute(loadRouteId.data[0].ROUTEID);
      }
    }
  };

  async function SaveHeaders(costingno) {
    try {
      const response = await axios.post(
        `${BASEURL}/InsertFinishedProductHeaders`,
        {
          costingno: costingno,
          date: date,
          username: username.split("@")[0].toUpperCase(),
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        alert(`Headers saved successfully`);
      } else {
        console.error(`Error while saving headers`);
      }
    } catch (error) {
      console.error(`Error while saving row headers`, error);
    }
  }

  async function UpdateFinishedProduct() {
    for (let index = 0; index < histheader.length; index++) {
      const value = histheader[index];
      try {
        const response = await axios.post(
          `${BASEURL}/InsertFinishedProduct`,
          {
            costingno: value.COSTINGSHEETNO,
            itemcode: value.ITEMCODE,
            qty: value.BAGS,
            cost: value.COSTPOSTPRODUCTIONANDTRANSPORT,
            transportcharges: value.TRANSPORTCOSTPERBAG,
            markup: value.MARKUPPERC,
            sellingprice: value.ITEMSELLINGPRICEAFTERTRANSPORT,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          console.log(`Row ${index + 1} saved successfully`);
        } else {
          console.error(`Error while saving row ${index + 1}`);
        }
      } catch (error) {
        console.error(`Error while saving row ${index + 1}`, error);
      }
    }
  }

  async function SaveFinishedProduct() {
    for (let index = 0; index < headeertable.length; index++) {
      const value = headeertable[index];
      try {
        const response = await axios.post(
          `${BASEURL}/InsertFinishedProduct`,
          {
            costingno: value.costing,
            itemcode: value.productcode,
            qty: value.noofbags,
            cost: value.costofbagaftertransport,
            transportcharges:
              Number(value.costofbagaftertransport) -
              Number(value.costbftransport),
            markup: value.markup,
            sellingprice: value.sellingPriceaftertransport,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          console.log(`Row ${index + 1} saved successfully`);
        } else {
          console.error(`Error while saving row ${index + 1}`);
        }
      } catch (error) {
        console.error(`Error while saving row ${index + 1}`, error);
      }
    }
  }

  async function DeleteFinishedProduct(value) {
    try {
      const response = await axios.post(
        `${BASEURL}/DeleteFinishedProduct`,
        {
          costingno: Number(costingno),
          itemcode: value,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        console.log(`Finished Product Deleted successfully`);
      } else {
        console.error(`Error while Deleting Finished Product `);
      }
    } catch (error) {
      console.error(`Error while Deleting Finished Product `, error);
    }
  }

  async function SaveTransport() {
    for (let index = 0; index < tabledata.length; index++) {
      const value = tabledata[index];
      try {
        const response = await axios.post(
          `${BASEURL}/InsertTransportCharges`,
          {
            costingno: costingno,
            itemcode: value.transportname,
            qty: totalnoofbags,
            cost: value.charge,
            route: Number(value.routee),
            fromlocation: value.from,
            tolocation: value.to,
            totalcost: value.totalcharge,
            costtype: value.fixedcharge == "V" ? 0 : 1,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          console.log(`Row ${index + 1} saved successfully`);
        } else {
          console.error(`Error while saving row ${index + 1}`);
        }
      } catch (error) {
        console.error(`Error while saving row ${index + 1}`, error);
      }
    }
  }

  async function DeleteTransport(value1, value2) {
    try {
      const response = await axios.post(
        `${BASEURL}/DeleteTransportCharges`,
        {
          costingno: Number(costingno),
          itemcode: value1,
          route: value2,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        console.log(`Transport Charges Deleted successfully`);
      } else {
        console.error(`Error while Deleting Transport Charges `);
      }
    } catch (error) {
      console.error(`Error while Deleting Transport Charges `, error);
    }
  }

  async function UpdateTransport() {
    for (let index = 0; index < updateddata.length; index++) {
      const value = updateddata[index];

      try {
        const response = await axios.post(
          `${BASEURL}/InsertTransportCharges`,
          {
            costingno: costingno,
            itemcode: value.TRANSPORTCHARGETYPE,
            qty: totalNoOfBagsHist,
            cost: value.SERVICERATE,
            route: value.ROUTEID,
            fromlocation: value.FROMLOCATION,
            tolocation: value.TOLOCATION,
            costtype: value.FIXEDORVARIABLE == "V" ? 0 : 1,
            totalcost: value.TOTALCOST,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          console.log(`Row ${index + 1} saved successfully`);
        } else {
          console.error(`Error while saving row ${index + 1}`);
        }
      } catch (error) {
        console.error(`Error while saving row ${index + 1}`, error);
      }
    }
  }

  const handleHistoricCLick = (value, index) => {
    setIndex(index);
    setflag(1);
    setSeltransportcharges(value.TRANSPORTCHARGETYPE);
    setCharges(value.SERVICERATE);
    setFixedCharges(value.FIXEDORVARIABLE);
    setFromLocation(value.FROMLOCATION);
    setToLocation(value.TOLOCATION);
    setmyRouteId(value.ROUTEID);
  };

  const handleTransportCLick = (value, index) => {
    settransindex(index);
    setTransflag(1);
    setSeltransportcharges(value.transportname);
    setCharges(value.charge);
    setFixedCharges(value.fixedcharge);
    setFromLocation(value.from);
    setToLocation(value.to);
    setmyRouteId(value.routee);
  };

  if (isloading) {
    return <LoadinScreen />;
  }

  return (
    <>
      <div>
        <AllNav />

        <h1 style={{ textAlign: "center" }}>
          Transport Costs After Production
        </h1>
        <div className="update_buttons">
          <button
            className="Save_import"
            onClick={() => {
              if (headeertable.length > 0) {
                if (window.confirm("Are you sure you don't want to Save")) {
                  window.location.reload();
                }
              } else {
                window.location.reload();
              }
            }}
          >
            New
          </button>
          <button
            className="Save_import"
            onClick={async () => {
              if (histheader.length > 0 || histtransport.length > 0) {
                if (updateddata.length > 0) {
                  await SaveHeaders(costingno);
                  await UpdateFinishedProduct();
                  await UpdateTransport();
                  alert("Saved as new Production Succesfully");
                  window.location.reload();
                } else {
                  alert("Alter the details to Update");
                }
              } else {
                if (headeertable.length > 0) {
                  await SaveHeaders(costingno);
                  await SaveFinishedProduct();
                  await SaveTransport();
                  alert("Saved Succesfully");
                  window.location.reload();
                } else {
                  alert("Fill all the details to save");
                }
              }
            }}
          >
            Save
          </button>
          <button
            className="Save_import"
            onClick={async () => {
              if (histheader.length > 0 || histtransport.length > 0) {
                if (updateddata.length > 0) {
                  await SaveHeaders(costingno);
                  await UpdateFinishedProduct();
                  await UpdateTransport();
                  alert("Updated Succesfully");
                  window.location.reload();
                } else {
                  alert("Alter the details to Update");
                }
              } else {
                alert("You can not update, click on Save");
              }
            }}
          >
            Update
          </button>
        </div>
        <div className="Raw_materials">
          <div className="raw_date">
            <div className="date">
              <p>Date </p>
            </div>
            <input
              className="input_date"
              type="Date"
              value={date.split("T")[0]}
            ></input>
          </div>

          <div className="first_line">
            <div className="cost_sheet_no">
              <p>Production No </p>
              <input
                className="input_size_bag4"
                type="text"
                value={costingno}
                readOnly
              ></input>
            </div>
            <button
              onClick={() => {
                clearallinputs();
                clearAll();
                setPopupOpen(true);
              }}
              style={{
                paddingLeft: " 5px",
                paddingRight: "5px",
                borderRadius: "5px",
                height: "30px",
                background: "#002d62",
                color: "white",
              }}
            >
              ...
            </button>
            &nbsp;
            {isPopupOpen && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                  background: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1000,
                }}
              >
                <div
                  style={{
                    position: "fixed",
                    width: "900px",
                    height: "90vh",
                    padding: "20px",
                    background: "white",
                    borderRadius: "8px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                    zIndex: 1001,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <p>Search :</p>&nbsp;
                      <input
                        type="text"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      ></input>
                    </div>
                    <button
                      onClick={() => setPopupOpen(false)}
                      className="Save_import"
                    >
                      Close
                    </button>
                  </div>

                  <aside
                    style={{
                      overflow: "auto",
                      height: "80vh",
                      paddingTop: "10px ",
                    }}
                  >
                    <table className="table_1">
                      <thead>
                        <tr className="table-header">
                          <td>Production No</td>
                          <td>Finished Product Code</td>
                          <td>finished Product Name</td>
                          <td>Cost Of Finished Product</td>
                          <td>Selling Price</td>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((row, index) => (
                          <tr
                            key={index}
                            onClick={() => {
                              setPopupOpen(false);
                              handleSelectionProd(row);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{row.BLENDINGNO ? row.BLENDINGNO : ""}</td>
                            <td>
                              {row.DERIVEDITEMCODE ? row.DERIVEDITEMCODE : ""}
                            </td>
                            <td>
                              {row.DERIVEDITEMDESCRIPTION
                                ? row.DERIVEDITEMDESCRIPTION
                                : ""}
                            </td>
                            <td>
                              {row.COSTPERSTANDARDKGDERIVED
                                ? row.COSTPERSTANDARDKGDERIVED.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : ""}
                            </td>
                            <td>
                              {row.DERIVEDSELLINGPRICE
                                ? row.DERIVEDSELLINGPRICE.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </aside>
                </div>
              </div>
            )}
            <div className="finished_product">
              <p>Finished Product </p>
              <input
                type="text"
                className="select_name"
                value={selItemName}
                readOnly
              ></input>

              <input
                type="text"
                className="select_code"
                value={selItemCode}
                readOnly
              ></input>
            </div>
            <div className="size_of_Bag_transp">
              <p>Size of Bag selected </p>
              <input
                type="text"
                className="input_size_bag4"
                value={selSize}
                readOnly
              ></input>
            </div>
          </div>
          <div className="third_line_transport">
            <div className="qty">
              <p>No of Bags </p>
              <input
                type="text"
                className="qty_input"
                value={selQuantity}
                onChange={(e) => {
                  if (!costingno) {
                    alert("Kindly select an Item first !!");
                    setselQuantity("");
                  } else if (histheader.length > 0 && selhistrow == null) {
                    alert("Kindly Select the row first");
                    setselQuantity("");
                  } else {
                    setselQuantity(e.target.value);
                  }
                }}
              ></input>
            </div>
            <div className="cost_prod">
              <p>Cost of Finished Product </p>
              <input
                type="text"
                className="input_cost"
                value={
                  costprice
                    ? costprice.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                readOnly
              ></input>
            </div>
            <div className="markup_perc_transport">
              <p>Markup Percentage </p>
              <input
                className="qty_input"
                value={markup ? markup : ""}
                readOnly
              ></input>
            </div>
            <div className="sel__for__product">
              <p>Selling Price Before Transport</p>
              <input
                value={
                  sellingprice
                    ? sellingprice.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                className="input_sp"
                readOnly
              ></input>
            </div>
          </div>

          <div className="button">
            <button
              className="update"
              onClick={() => {
                if (histheader.length > 0) {
                  UpdateCalculations();
                } else {
                  alert(
                    "Kindly choose the production number which is already saved !!"
                  );
                }
              }}
            >
              Update Cost
            </button>
            <button
              className="Add"
              onClick={() => {
                if (histheader.length > 0) {
                  UpdateHistHeaders();
                } else {
                  AddHeaders();
                }
              }}
            >
              Add
            </button>
            <button
              className="Add"
              onClick={() => {
                if (histheader.length > 0) {
                  RemoveHeaderHistoric();
                } else {
                  RemoveHeader();
                }
              }}
            >
              Remove
            </button>
          </div>
        </div>

        {showheader && (
          <div className="visible">
            <table className="table_1">
              <thead>
                <tr className="table-header">
                  <td>Production No</td>
                  <td>Item code</td>
                  <td style={{ paddingLeft: "40px" }}>Item Name</td>
                  <td>No Of Bags</td>
                  <td>Size Of Bag</td>
                  <td>Cost of bag before Transport</td>
                  <td>Transport charges Per bag</td>
                  <td>Cost of bag after Transport</td>
                  <td>Markup %</td>
                  <td>selling Price of bag befotre transport</td>
                  <td>selling Price of bag after transport</td>
                </tr>
              </thead>
              <tbody>
                {histheader.length > 0
                  ? histheader.map((value, index) => (
                      <tr
                        onClick={() => {
                          handlehistheaders(value, index);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td style={{ textAlign: "left" }}>
                          {value.COSTINGSHEETNO}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "50px" }}
                        >
                          {value.ITEMCODE}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "40px" }}
                        >
                          {value.ITEMNAME}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "50px" }}
                        >
                          {value.BAGS}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "50px" }}
                        >
                          {value.SIZEOFBAG}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {value.COSTPOSTPRODUCTION
                            ? value.COSTPOSTPRODUCTION.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : ""}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {value.TRANSPORTCOSTPERBAG
                            ? value.TRANSPORTCOSTPERBAG.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : ""}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {value.COSTPOSTPRODUCTIONANDTRANSPORT
                            ? value.COSTPOSTPRODUCTIONANDTRANSPORT.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : ""}
                        </td>
                        <td style={{ textAlign: "left", paddingLeft: "18px" }}>
                          {value.MARKUPPERC}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "48px" }}
                        >
                          {value.ITEMSELLINGPRICEBEDFORETRANSPORT
                            ? value.ITEMSELLINGPRICEBEDFORETRANSPORT.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : ""}
                        </td>

                        <td
                          style={{ textAlign: "right", paddingRight: "48px" }}
                        >
                          {value.ITEMSELLINGPRICEAFTERTRANSPORT
                            ? value.ITEMSELLINGPRICEAFTERTRANSPORT.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : ""}
                        </td>
                      </tr>
                    ))
                  : headeertable.map((value, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          handleHeaders(value, index);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td
                          style={{ textAlign: "right", paddingRight: "50px" }}
                        >
                          {value.costing}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {value.productcode}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "50px" }}
                        >
                          {value.productname}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "40px" }}
                        >
                          {value.noofbags}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "50px" }}
                        >
                          {value.sizeofbag}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {value.costbftransport
                            ? value.costbftransport.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : ""}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {value.costofbagaftertransport
                            ? (
                                Number(value.costofbagaftertransport) -
                                Number(value.costbftransport)
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : ""}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {value.costofbagaftertransport
                            ? value.costofbagaftertransport.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : ""}
                        </td>

                        <td style={{ textAlign: "left", paddingLeft: "18px" }}>
                          {value.markup}
                        </td>

                        <td
                          style={{ textAlign: "right", paddingRight: "48px" }}
                        >
                          {value.spbftransport
                            ? value.spbftransport.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : ""}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "48px" }}
                        >
                          {value.sellingPriceaftertransport
                            ? value.sellingPriceaftertransport.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : ""}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="next_line">
        <div className="import">
          <p>FINISHED PRODUCT TO TRANSPORT</p>
        </div>
      </div>
      <div className="Derived_items_1">
        <div className="first_line1">
          <div className="trans_charges">
            <p>Transport charges </p>
            <Select
              className="pro_select_1"
              onChange={(selectedOption) => {
                if (headeertable.length > 0 || histheader.length > 0) {
                  if (selectedOption.value == "") {
                    alert("Kindly Choose Proper Transport Charges");
                  } else {
                    setSeltransportcharges(selectedOption.value);
                    handleOptionChange(selectedOption);
                  }
                } else {
                  alert("Kindly add finished product first");
                }
              }}
              value={
                seltransportcharges
                  ? { label: seltransportcharges, value: seltransportcharges }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...transportcharges.map((item) => ({
                  value: item.NONSTOCKITEM,
                  label: item.NONSTOCKITEM,
                })),
              ]}
            />
          </div>
          <div className="charges">
            <p>Charges </p>

            <input
              type="text"
              className="input_sp1"
              value={charges}
              readOnly={true}
            ></input>
          </div>
          <div className="charge_f">
            <p>Charge F/V </p>
            <input
              type="text"
              className="input_sp1"
              value={fixedcharges}
              readOnly={true}
            ></input>
          </div>
        </div>
        <div className="">
          <main className="second_line_1">
            <aside>
              <div className="select_from">
                <p>From &nbsp;</p>
                <Select
                  className="pro_select_1"
                  onChange={(selectedOption) => {
                    if (seltransportcharges) {
                      if (selectedOption.value == "") {
                        alert("Kindly Choose Proper From Location");
                      } else {
                        if (ToLocation) {
                          setToLocation("");
                          setmyRouteId("");
                        }
                        setFromLocation(selectedOption.value);
                      }
                    } else {
                      alert("Kindly select Transport Charges");
                    }
                  }}
                  value={
                    FromLocation
                      ? { label: FromLocation, value: FromLocation }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...route.map((item) => ({
                      value: item.ROUTEFROM,
                      label: item.ROUTEFROM,
                    })),
                  ]}
                />
              </div>
            </aside>
            <aside>
              <div className="select_to">
                <p>To &nbsp;</p>
                <Select
                  className="pro_select_1"
                  onChange={(selectedOption) => {
                    if (FromLocation) {
                      if (selectedOption.value == "") {
                        alert("Kindly Choose Proper To Location");
                      } else {
                        setToLocation(selectedOption.value);
                        getRouteId(selectedOption.value);
                      }
                    } else {
                      alert("Kindly select FromLocation");
                    }
                  }}
                  value={
                    ToLocation
                      ? { label: ToLocation, value: ToLocation }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...route1.map((item) => ({
                      value: item.ROUTETO,
                      label: item.ROUTETO,
                    })),
                  ]}
                />
              </div>
            </aside>
            <aside className="route_id">
              <p>Route Id &nbsp;</p>
              <div>
                <input
                  style={{
                    color: "red",
                    fontWeight: 600,
                    pointerEvents: "none",
                  }}
                  className="input_sp2"
                  type="text"
                  id="routeid"
                  value={myRouteId}
                  readOnly
                />
              </div>
            </aside>
          </main>
        </div>
        <div className="button">
          <button
            className="Add"
            onClick={() => {
              if (histtransport.length > 0) {
                if (flag === 1) {
                  handleModify();
                  setflag(0);
                } else {
                  addnewTransport();
                }
              } else {
                if (myRouteId) {
                  AddTransportCharges();
                } else {
                  alert("Kindly fill the details first");
                }
              }
            }}
          >
            Add
          </button>
          <button
            className="Add"
            onClick={() => {
              if (histtransport.length > 0) {
                RemoveTransportHistoric();
              } else {
                RemoveTransport();
              }
            }}
          >
            Remove
          </button>
        </div>
      </div>

      {showTable && (
        <div className="visible">
          <table className="table_1">
            <thead>
              <tr className="table-header">
                <td>Transport charge Name</td>
                <td style={{ paddingLeft: "40px" }}>charge</td>
                <td> Route Id</td>
                <td>From Location</td>
                <td>To Location</td>
                <td>Total Charge</td>
                <td> charge type</td>
              </tr>
            </thead>
            <tbody>
              {updateddata.length > 0
                ? updateddata.map((value, index) => (
                    <tr
                      onClick={() => {
                        handleHistoricCLick(value, index);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <td style={{ textAlign: "left" }}>
                        {value.TRANSPORTCHARGETYPE}
                      </td>
                      <td style={{ textAlign: "right", paddingRight: "40px" }}>
                        {value.SERVICERATE
                          ? value.SERVICERATE.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""}
                      </td>
                      <td style={{ textAlign: "right", paddingRight: "50px" }}>
                        {value.ROUTEID}
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {value.FROMLOCATION}
                      </td>
                      <td style={{ textAlign: "left", paddingLeft: "18px" }}>
                        {value.TOLOCATION}
                      </td>
                      <td style={{ textAlign: "right", paddingRight: "48px" }}>
                        {value.TOTALCOST
                          ? value.TOTALCOST.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""}
                      </td>
                      <td style={{ textAlign: "right", paddingRight: "50px" }}>
                        {value.FIXEDORVARIABLE == 0 ? "V" : "F"}
                      </td>
                    </tr>
                  ))
                : histtransport.length > 0
                ? histtransport.map((value, index) => (
                    <tr
                      onClick={() => {
                        handleHistoricCLick(value, index);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <td style={{ textAlign: "left" }}>
                        {value.TRANSPORTCHARGETYPE}
                      </td>

                      <td style={{ textAlign: "right", paddingRight: "40px" }}>
                        {value.SERVICERATE
                          ? value.SERVICERATE.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""}
                      </td>
                      <td style={{ textAlign: "right", paddingRight: "50px" }}>
                        {value.ROUTEID}
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {value.FROMLOCATION}
                      </td>
                      <td style={{ textAlign: "left", paddingLeft: "18px" }}>
                        {value.TOLOCATION}
                      </td>
                      <td style={{ textAlign: "right", paddingRight: "48px" }}>
                        {value.TOTALCOST
                          ? value.TOTALCOST.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""}
                      </td>
                      <td style={{ textAlign: "right", paddingRight: "50px" }}>
                        {value.FIXEDORVARIABLE == 0 ? "V" : "F"}
                      </td>
                    </tr>
                  ))
                : tabledata.map((value, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        handleTransportCLick(value, index);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <td style={{ textAlign: "left" }}>
                        {value.transportname}
                      </td>
                      <td style={{ textAlign: "right", paddingRight: "40px" }}>
                        {value.charge
                          ? value.charge.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""}
                      </td>
                      <td style={{ textAlign: "right", paddingRight: "50px" }}>
                        {value.routee}
                      </td>
                      <td style={{ textAlign: "left" }}>{value.from}</td>
                      <td style={{ textAlign: "left", paddingLeft: "18px" }}>
                        {value.to}
                      </td>
                      <td style={{ textAlign: "right", paddingRight: "48px" }}>
                        {value.charge
                          ? (totalnoofbags * value.charge).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : ""}
                      </td>
                      <td style={{ textAlign: "right", paddingRight: "50px" }}>
                        {value.fixedcharge}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>

          <div className="total_Bag_1">
            <p>Total Transport/Logistics Costs :</p>
            <input
              type="text"
              className="input_total_Bag"
              value={
                finalcost1
                  ? finalcost1.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : finalcost
                  ? finalcost.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : ""
              }
            ></input>
          </div>
        </div>
      )}
      <div className="button"></div>
    </>
  );
};

export default Transport;
