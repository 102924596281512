import React from "react";
import "../CSS/HomePage.css";

import Navbar from "../Components/Navbar/Navbar";
import InfiniteCardSlider from "../Functionality/Advertising/InfiniteCardSlider";
function Home() {
  console.log("inside");
  return (
    <div>
      <Navbar />

      <InfiniteCardSlider />
    </div>
  );
}
export default Home;
