import React, { useEffect, useState } from "react";
import "../CSS/ItemMaster.css";
import axios from "axios";
import Select from "react-select";
import AllNav from "../Components/Navbar/AllNav";
import LoadingScreen from "./LoadingScreen";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";

const ChargesMaster = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [item, setItem] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [chargeCategory, setChargeCategory] = useState("");
  const [chargename, setChargeName] = useState("");
  const [charge, setCharge] = useState("");
  const [fixedorvariable, setfixedorvariable] = useState(false);
  const [listOfCategory, setListOfcategory] = useState([]);
  const [historic, setHistoric] = useState(false);
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    getChargeCatehory();
    FetchChargesList();
  }, []);

  const filteredData = item.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const stat = status;

    if (searchData.length === 0 && !stat) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["CATEGORY", "CHARGE", "CHARGEAMOUNT"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const statusMatch =
      !status ||
      (status === "VARIABLE"
        ? item.FIXEDVARIABLE == 0
        : status === "FIXED"
        ? item.FIXEDVARIABLE == 1
        : "");

    return textMatch && statusMatch;
  });

  const customStylessSelect = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "250px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const getChargeCatehory = () => {
    axios
      .get(`${BASEURL}/getChargeCatehory`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setListOfcategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Charges details:", error);
      });
  };

  const FetchChargesList = () => {
    axios
      .get(`${BASEURL}/FetchChargesList`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setisloading(false);
        setItem(response.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Charges details:", error);
      });
  };

  // Saving
  async function SaveChargesMaster() {
    setisloading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/InsertChargesMaster`,
        {
          CHARGE: chargename,
          AMOUNT: Number(charge),
          CATEGORY: chargeCategory,
          FIXEDVARIABLE: fixedorvariable == true ? 1 : 0,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        setisloading(false);
        console.log(`Charges Master saved successfully`);
      } else {
        setisloading(false);
        console.error(`Error while saving Charges Master`);
      }
    } catch (error) {
      setisloading(false);
      console.error(`Error while saving Charges Master`, error);
    }
  }

  const handleRowClick = (value, index) => {
    setHistoric(true);
    setChargeCategory(value.CATEGORY);
    setChargeName(value.CHARGE);
    setCharge(value.CHARGEAMOUNT);
    setfixedorvariable(value.FIXEDVARIABLE == 0 ? false : true);
  };

  const ExcelStockData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("List Of charges");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [15, 50, 25, 25, 25, 15];

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "CATEGORY",
      "CHARGE",
      "CHARGEAMOUNT",
      "FIXEDVARIABLE",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "List_Of_Charges.xlsx";
      link.click();
    });
  };

  const PdfStockData = () => {
    const doc = new jsPDF();

    doc.text("List Of Charges", 10, 10);

    const columns = ["CATEGORY", "CHARGE", "CHARGEAMOUNT", "FIXEDVARIABLE"];

    const data = filteredData.map((stock) => [
      stock.CATEGORY,
      stock.CHARGE,
      stock.CHARGEAMOUNT,
      stock.FIXEDVARIABLE == 0 ? "VARIABLE" : "FIXED",
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("List_Of_Charges.pdf");
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AllNav />

      <h1 style={{ textAlign: "center" }}>Charges Master</h1>
      <div className="header_route">
        <div className="flex">
          <button
            className="Save_import"
            onClick={() => {
              if (chargeCategory || chargename) {
                if (window.confirm("Are you sure you don't want to Save")) {
                  window.location.reload();
                }
              } else {
                window.location.reload();
              }
            }}
          >
            New
          </button>

          <button
            className="Save_import"
            onClick={async () => {
              if (!chargeCategory) {
                alert("Kindly Supply Charge Category");
              } else if (!chargename) {
                alert("Kindly Supply Charge Name");
              } else if (Number(charge) <= 0) {
                alert("Kindly Provide Charges Greater than 0");
              } else {
                await SaveChargesMaster();
                alert("Saved Succesfully");
                window.location.reload();
              }
            }}
          >
            Save
          </button>

          {/* <button
            className="Save_import"
            onClick={async () => {
              if (!chargeCategory) {
                alert("Kindly Supply Charge Category");
              } else if (!chargename) {
                alert("Kindly Supply Charge Name");
              } else if (Number(charge) <= 0) {
                alert("Kindly Provide Charges Greater than 0");
              } else {
                await SaveChargesMaster();
                alert("Updated Succesfully");
                window.location.reload();
              }
            }}
          >
            Update
          </button> */}
        </div>
      </div>
      <section className="section__Item">
        <div className="section__div">
          <div className="item_second_line">
            <div className="category_route">
              <p>Charge Category</p>
              <div
                className="select__margin__master"
                style={{ marginRight: "0px" }}
              >
                <Select
                  className="pro_select_1"
                  onChange={(selectedOption) => {
                    if (selectedOption.value === "") {
                      alert("Kinly select Proper Category");
                    } else {
                      setChargeCategory(selectedOption.value);
                    }
                  }}
                  value={
                    chargeCategory
                      ? {
                          label: chargeCategory,
                          value: chargeCategory,
                        }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...listOfCategory.map((item) => ({
                      value: item.CATEGORY,
                      label: item.CATEGORY,
                    })),
                  ]}
                  isDisabled={historic == true}
                />
              </div>
            </div>

            <div className="item_name" style={{ marginRight: "0px" }}>
              <p>Charge Name</p>
              <input
                type="text"
                className="select_name"
                value={chargename}
                onChange={(e) => {
                  setChargeName(e.target.value);
                }}
                disabled={historic == true}
              />
            </div>
            <div className="item_code_route">
              <p>Charge Cost</p>
              <input
                type="text"
                className="select_code"
                value={charge}
                onChange={(e) => {
                  setCharge(e.target.value);
                }}
              />
            </div>
            <div>
              <p>Charge Type</p>
              <input
                type="checkbox"
                checked={fixedorvariable}
                onChange={() => {
                  setfixedorvariable(!fixedorvariable);
                }}
              />
            </div>
          </div>
        </div>
        {/* 
        <div className="section__div">
          <div className="item_code_table"> */}
        <div className="select_product_type" style={{ marginTop: "5px" }}>
          <div>
            <p>Search</p>
          </div>
          <div>
            <input
              type="text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
          </div>
          <div>
            <p>Charge Type</p>
          </div>
          <div>
            <Select
              styles={customStylessSelect}
              onChange={(e) => {
                if (e.value === "") {
                  alert("Kindly Supply proper Product Type");
                  setStatus("");
                } else {
                  setStatus(e.value);
                }
              }}
              value={
                status
                  ? { label: status, value: status }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                { value: "VARIABLE", label: "VARIABLE" },
                { value: "FIXED", label: "FIXED" },
              ]}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "620px",
              justifyContent: "flex-end",
              marginTop: "5px",
            }}
          >
            <div style={{ marginRight: "5px" }}>
              <RiFileExcel2Line onClick={ExcelStockData} size={35} />
            </div>
            <div>
              {" "}
              <BsFileEarmarkPdf onClick={PdfStockData} size={35} />
            </div>
          </div>
        </div>
        {/* </div>
        </div> */}

        <div
          className="visible "
          style={{
            height: "auto",
            padding: "4px",
            zIndex: "0",
          }}
        >
          <table className="table_1">
            <thead>
              <tr className="table-header">
                <td
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "20px",
                    textAlign: "right",
                  }}
                >
                  SNO
                </td>
                <td>CHARGE CATEGORY</td>
                <td>CHARGE NAME</td>
                <td style={{ textAlign: "right", paddingRight: "50px" }}>
                  CHARGE COST
                </td>
                <td>CHARGE TYPE</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((value, index) => (
                <tr
                  onClick={() => {
                    handleRowClick(value, index);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "20px",
                      textAlign: "right",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "30px" }}>
                    {value.CATEGORY ? value.CATEGORY : "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.CHARGE ? value.CHARGE : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "50px" }}>
                    {value.CHARGEAMOUNT
                      ? value.CHARGEAMOUNT.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0.0}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.FIXEDVARIABLE == 0 ? "VARIABLE" : "FIXED" || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default ChargesMaster;
