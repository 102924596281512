import React, { useEffect, useState } from "react";
import "../../CSS/ItemMaster.css";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import LoadingScreen from "../LoadingScreen";
import AllNav from "../../Components/Navbar/AllNav";

const CostingTransportReport = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [item, setItem] = useState([]);
  const [search, setSearch] = useState("");
  const [status, SetStatus] = useState("");
  const [isloading, setisloading] = useState(true);

  const customStylessSelect = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "120px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const filteredData = item.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "OT_COSTING_SHEET_NO",
        "OM_ITEM_CODE",
        "OM_ITEM_DESCRIPTION",
        "OT_COSTING_SHEET_COST_ITEM",
        "OT_COSTING_SHEET_COST_ITEM_RATE",
        "OT_ROUTE_ID",
        "OT_ROUTE_FROM_LOCATION",
        "OT_ROUTE_TO_LOCATION",
        "OT_COST_TOTAL_ON_TONNAGE",
        "OT_COST_TYPE_FIXED_VARIABLE",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  const ExcelStockData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Costing Transport Report");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [10, 15, 35, 35, 15, 10, 15, 15, 15, 15];

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "COSTING SHEET NO",
      "ITEM CODE",
      "ITEM NAME",
      "TRANSPORT CHARGE NAME",
      "TRANSPORT CHARGES",
      "ROUTE ID",
      "FROM LOCATION",
      "TO LOCATION",
      "TOTAL COST",
      "CHARGE TYPE",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Costing_Transport_Report.xlsx";
      link.click();
    });
  };

  const PdfStockData = () => {
    const doc = new jsPDF();

    doc.text("Costing Transport - Report", 10, 10);

    const columns = [
      "COSTING SHEET NO",
      "ITEM CODE",
      "ITEM NAME",
      "TRANSPORT CHARGE NAME",
      "TRANSPORT CHARGES",
      "ROUTE ID",
      "FROM LOCATION",
      "TO LOCATION",
      "TOTAL COST",
      "CHARGE TYPE",
    ];
    const data = filteredData.map((stock) => [
      stock.OT_COSTING_SHEET_NO ? stock.OT_COSTING_SHEET_NO : "N/A",
      stock.OM_ITEM_CODE ? stock.OTEM_CODE : "N/A",
      stock.OM_ITEM_DESCRIPTION ? stock.OM_ITEM_DESCRIPTION : "N/A",
      stock.OT_COSTING_SHEET_COST_ITEM
        ? stock.OT_COSTING_SHEET_COST_ITEM
        : "N/A",
      stock.OT_COSTING_SHEET_COST_ITEM_RATE
        ? stock.OT_COSTING_SHEET_COST_ITEM_RATE.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "N/A",
      stock.OT_ROUTE_ID ? stock.OT_ROUTE_ID : "N/A",
      stock.OT_ROUTE_FROM_LOCATION ? stock.OT_ROUTE_FROM_LOCATION : "N/A",
      stock.OT_ROUTE_TO_LOCATION ? stock.OT_ROUTE_TO_LOCATION : "N/A",
      stock.OT_COST_TOTAL_ON_TONNAGE
        ? stock.OT_COST_TOTAL_ON_TONNAGE.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "N/A",
      stock.OT_COST_TYPE_FIXED_VARIABLE
        ? stock.OT_COST_TYPE_FIXED_VARIABLE === "V"
          ? "VARIABLE"
          : stock.OT_COST_TYPE_FIXED_VARIABLE === "F"
          ? "FIXED"
          : ""
        : "N/A",
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("Costing_Transport_Report.pdf");
  };

  useEffect(() => {
    getItemDetails();
  }, []);

  const getItemDetails = () => {
    axios
      .get(`${BASEURL}/CostingTransportReport`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setisloading(false);
        setItem(response.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Costing Transport Reports:", error);
      });
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AllNav />
      <h1 style={{ textAlign: "center" }}>
        Transport Costs After Production - Report
      </h1>

      <section className="section__Item">
        <div className="section__div">
          <div className="item_code_table">
            <div className="select_product_type">
              <div>
                <p>Search</p>
              </div>
              <div>
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              <div>
                <RiFileExcel2Line onClick={ExcelStockData} size={35} />
              </div>
              <div>
                {" "}
                <BsFileEarmarkPdf onClick={PdfStockData} size={35} />
              </div>
            </div>
          </div>
        </div>

        <div className="visible">
          <table className="table_1">
            <thead>
              <tr className="table-header">
                <td>COSTING SHEET NO</td>
                <td>ITEM CODE</td>
                <td>ITEM NAME</td>
                <td>TRANSPORT CHARGE NAME</td>
                <td>TRANSPORT CHARGES</td>
                <td>ROUTE ID</td>
                <td>FROM LOCATION</td>
                <td>TO LOCATION</td>
                <td>TOTAL COST</td>
                <td>CHARGE TYPE</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((value, index) => (
                <tr>
                  <td style={{ textAlign: "right" }}>
                    {value.OT_COSTING_SHEET_NO
                      ? value.OT_COSTING_SHEET_NO
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {value.OM_ITEM_CODE ? value.OM_ITEM_CODE : "N/A"}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {value.OM_ITEM_DESCRIPTION
                      ? value.OM_ITEM_DESCRIPTION
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {value.OT_COSTING_SHEET_COST_ITEM
                      ? value.OT_COSTING_SHEET_COST_ITEM
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "22px" }}>
                    {value.OT_COSTING_SHEET_COST_ITEM_RATE
                      ? value.OT_COSTING_SHEET_COST_ITEM_RATE.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "22px" }}>
                    {value.OT_ROUTE_ID ? value.OT_ROUTE_ID : "N/A"}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {value.OT_ROUTE_FROM_LOCATION
                      ? value.OT_ROUTE_FROM_LOCATION
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingLeft: "18px" }}>
                    {value.OT_ROUTE_TO_LOCATION
                      ? value.OT_ROUTE_TO_LOCATION
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "22px" }}>
                    {value.OT_COST_TOTAL_ON_TONNAGE
                      ? value.OT_COST_TOTAL_ON_TONNAGE.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {value.OT_COST_TYPE_FIXED_VARIABLE
                      ? value.OT_COST_TYPE_FIXED_VARIABLE == 0
                        ? "VARIABLE"
                        : value.OT_COST_TYPE_FIXED_VARIABLE == 1
                        ? "FIXED"
                        : ""
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default CostingTransportReport;
