import React, { useEffect, useState } from "react";
import "../../CSS/ItemMaster.css";
import axios from "axios";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import LoadingScreen from "../LoadingScreen";
import AllNav from "../../Components/Navbar/AllNav";
import { costingno } from "../../Redux/Action/costingno";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FaSortAmountUpAlt } from "react-icons/fa";
import { FaSortAmountDownAlt } from "react-icons/fa";
import ReactApexChart from "react-apexcharts";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
const ImportCostingReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [item, setItem] = useState([]);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("descending");
  const [isloading, setisloading] = useState(true);
  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const localStartOfMonth = new Date(
    startOfMonth.getTime() - startOfMonth.getTimezoneOffset() * 60000
  );
  const [startdate, setstartdate] = useState(localStartOfMonth.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());

  const filteredData = item.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.OT_COSTING_SHEET_DATE;
    const eDate = item.OT_COSTING_SHEET_DATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "ITEMCODE",
        "ITEMDESCRIPTION",
        "OT_COSTING_SHEET_NO",
        "OT_COSTING_SHEET_QTY",
        "OT_COSTING_SHEET_CHARGE_PER_MT_INCLUSIVE",
        "OT_COSTING_SHEET_CHARGE_PER_KILO_INCLUSIVE",
        "OT_COSTING_SHEET_CHARGE_PER_90KG_INCLUSIVE",
        "SUPPLIERNAME",
        "VESSELNAME",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  const sortTableData = (data) => {
    if (sortOrder === "ascending") {
      return data.sort(
        (a, b) =>
          new Date(a.OT_COSTING_SHEET_DATE) - new Date(b.OT_COSTING_SHEET_DATE)
      );
    } else {
      return data.sort(
        (a, b) =>
          new Date(b.OT_COSTING_SHEET_DATE) - new Date(a.OT_COSTING_SHEET_DATE)
      );
    }
  };

  const sortedData = sortTableData(filteredData);

  const filterUniqueByKey = (array, key) => {
    const seen = new Set();
    return array.filter((item) => {
      const value = item[key];
      if (seen.has(value)) {
        return false;
      } else {
        seen.add(value);
        return true;
      }
    });
  };

  // Then, use this function to filter out duplicates based on OT_COSTING_SHEET_NO
  const uniqueData = filterUniqueByKey(sortedData, "OT_COSTING_SHEET_NO");

  const ExcelStockData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Import Costing Report");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [10, 15, 35, 30, 15, 15, 10, 15, 15, 15];

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "COSTING NO",
      "ITEM CODE",
      "ITEM NAME",
      "SUPPLIER NAME",
      "VESSEL NAME",
      "DATE",
      "QUANTITY",
      "COST PER MT INCL VAT",
      "COST PER KG INCL VAT",
      "COST PER 90KG INCL VAT",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Import_Costing_Report.xlsx";
      link.click();
    });
  };

  const PdfStockData = () => {
    const doc = new jsPDF();

    doc.text("Import Costing - Report", 10, 10);

    const columns = [
      "COSTING NO",
      "ITEM CODE",
      "ITEM NAME",
      "SUPPLIER NAME",
      "VESSEL NAME",
      "DATE",
      "QUANTITY",
      "COST PER MT INCL VAT",
      "COST PER KG INCL VAT",
      "COST PER 90KG INCL VAT",
    ];
    const data = filteredData.map((stock) => [
      stock.OT_COSTING_SHEET_NO,
      stock.ITEMCODE,
      stock.ITEMDESCRIPTION,
      stock.SUPPLIERNAME,
      stock.VESSELNAME,
      moment(stock.OT_COSTING_SHEET_DATE).format("DD/MM/YYYY"),
      stock.OT_COSTING_SHEET_QTY,
      stock.OT_COSTING_SHEET_CHARGE_PER_MT_INCLUSIVE,
      stock.OT_COSTING_SHEET_CHARGE_PER_KILO_INCLUSIVE,
      stock.OT_COSTING_SHEET_CHARGE_PER_90KG_INCLUSIVE,
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("Import_Costing_Report.pdf");
  };

  useEffect(() => {
    getItemDetails();
  }, []);

  const getItemDetails = () => {
    axios
      .get(`${BASEURL}/ImportCostingReport`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setisloading(false);
        setItem(response.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Import Costing Reports:", error);
      });
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AllNav />
      <h1 style={{ textAlign: "center" }}>Import Costing - Report</h1>

      <section className="section__Item">
        <div className="section__div">
          <div className="item_code_table">
            <div className="select_product_type">
              <div>
                <p>Search</p>
              </div>
              <div>
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              <p>Start Date:</p>
              <input
                type="date"
                onChange={(e) => {
                  if (e.target.value > enddate) {
                    alert("start Date Cannot be greater than end date");
                  } else {
                    setstartdate(e.target.value);
                  }
                }}
                value={startdate.split("T")[0]}
              ></input>
              <p>End Date:</p>
              <input
                type="date"
                onChange={(e) => {
                  if (e.target.value < startdate) {
                    alert("end Date Cannot be less than start date");
                  } else {
                    setEnddate(e.target.value);
                  }
                }}
                value={enddate.split("T")[0]}
              ></input>
              <div>
                <RiFileExcel2Line onClick={ExcelStockData} size={35} />
              </div>
              <div>
                {" "}
                <BsFileEarmarkPdf onClick={PdfStockData} size={35} />
              </div>
            </div>
          </div>
        </div>

        <div className="visible">
          <table className="table_1">
            <thead>
              <tr className="table-header">
                <td>COSTING NO</td>
                <td>ITEM CODE</td>
                <td>ITEM NAME</td>
                <td>SUPPLIER NAME</td>
                <td>VESSEL NAME</td>
                <td
                  onClick={() =>
                    setSortOrder(
                      sortOrder === "ascending" ? "descending" : "ascending"
                    )
                  }
                >
                  <h4
                    className="cursor__pointer"
                    style={{ textAlign: "right", paddingRight: "10px" }}
                  >
                    DATE &nbsp;
                    {sortOrder === "descending" ? (
                      <FaSortAmountDownAlt />
                    ) : (
                      <FaSortAmountUpAlt />
                    )}
                  </h4>
                </td>
                <td>QUANTITY</td>
                <td>COST PER MT INCL VAT</td>
                <td>COST PER KG INCL VAT</td>
                <td>COST PER 90KG INCL VAT</td>
              </tr>
            </thead>
            <tbody>
              {uniqueData.map((value, index) => (
                <tr
                  key={value.OT_COSTING_SHEET_NO}
                  onClick={() => {
                    dispatch(costingno(value.OT_COSTING_SHEET_NO));
                    navigate("/ImportCosting");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td style={{ textAlign: "right", paddingRight: "22px" }}>
                    {value.OT_COSTING_SHEET_NO
                      ? value.OT_COSTING_SHEET_NO
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "22px" }}>
                    {value.ITEMCODE ? value.ITEMCODE : "N/A"}
                  </td>
                  <td>
                    {value.ITEMDESCRIPTION ? value.ITEMDESCRIPTION : "N/A"}
                  </td>
                  <td>{value.SUPPLIERNAME ? value.SUPPLIERNAME : "N/A"}</td>
                  <td>{value.VESSELNAME ? value.VESSELNAME : "N/A"}</td>
                  <td>
                    {value.OT_COSTING_SHEET_DATE
                      ? moment(value.OT_COSTING_SHEET_DATE).format("DD/MM/YYYY")
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "22px" }}>
                    {value.OT_COSTING_SHEET_QTY
                      ? value.OT_COSTING_SHEET_QTY.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "22px" }}>
                    {value.OT_COSTING_SHEET_CHARGE_PER_MT_INCLUSIVE
                      ? value.OT_COSTING_SHEET_CHARGE_PER_MT_INCLUSIVE.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "22px" }}>
                    {value.OT_COSTING_SHEET_CHARGE_PER_KILO_INCLUSIVE
                      ? value.OT_COSTING_SHEET_CHARGE_PER_KILO_INCLUSIVE.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "22px" }}>
                    {value.OT_COSTING_SHEET_CHARGE_PER_90KG_INCLUSIVE
                      ? value.OT_COSTING_SHEET_CHARGE_PER_90KG_INCLUSIVE.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
      <div style={{ width: "95%", margin: "0 auto", marginBottom: "400px" }}>
        {isloading ? (
          <LoadingScreen />
        ) : (
          <>
            {uniqueData.length > 0 ? (
              <div>
                <div className="chart__active__buttons">
                  <button
                    className={
                      chartType === "bar"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("bar")}
                  >
                    <IoBarChartOutline />
                  </button>
                  <button
                    className={
                      chartType === "pie"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("pie")}
                  >
                    <FaChartPie />
                  </button>
                  <button
                    className={
                      chartType === "scatter"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("scatter")}
                  >
                    <BiScatterChart />
                  </button>
                  <button
                    className={
                      chartType === "area"
                        ? "active__chart "
                        : "not__active__chart  "
                    }
                    onClick={() => handleButtonClick("area")}
                  >
                    <FaChartArea />
                  </button>
                </div>
                {chartType === "bar" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar-chart",
                      },
                      xaxis: {
                        categories: uniqueData.map(
                          (item) => item?.ITEMDESCRIPTION
                        ),
                        title: {
                          text: "ITEM DESCRIPTION",
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "COST PER MT INCL VAT",
                          },
                        },
                        {
                          title: {
                            text: "COST PER KG INCL VAT	",
                          },
                          opposite: true,
                        },
                      ],
                      dataLabels: {
                        enabled: false,
                      },
                    }}
                    series={[
                      {
                        name: "SHEET CHARGE PER MT INCLUSIVE",
                        data: uniqueData.map(
                          (item) =>
                            item?.OT_COSTING_SHEET_CHARGE_PER_MT_INCLUSIVE
                        ),
                      },
                      {
                        name: "CHARGE PER KILO INCLUSIVE",

                        data: uniqueData.map(
                          (item) =>
                            item?.OT_COSTING_SHEET_CHARGE_PER_KILO_INCLUSIVE
                        ),
                      },
                    ]}
                    type="bar"
                    height={400}
                  />
                )}
                {chartType === "area" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "area-chart", // Change the ID for the area chart
                      },
                      xaxis: {
                        categories: uniqueData.map(
                          (item) => item?.ITEMDESCRIPTION
                        ),
                        title: {
                          text: "ITEM DESCRIPTION", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "COST PER KG INCL VAT	", // Y-axis label
                          },
                        },
                      ],
                      dataLabels: {
                        enabled: false, // Disable the data labels for area chart
                      },
                    }}
                    series={[
                      {
                        name: "COST PER KG INCL VAT	",
                        data: uniqueData.map(
                          (item) =>
                            item?.OT_COSTING_SHEET_CHARGE_PER_MT_INCLUSIVE
                        ),
                      },
                      {
                        name: "COST PER MT INCL VAT	",
                        data: uniqueData.map(
                          (item) =>
                            item?.OT_COSTING_SHEET_CHARGE_PER_KILO_INCLUSIVE
                        ),
                      },
                    ]}
                    type="area" // Change chart type to "area"
                    height={300}
                  />
                )}
                {chartType === "pie" && (
                  <div className="flex__pai">
                    <aside className="for__single__pai">
                      {" "}
                      {/* Adjust the width of each pie chart */}
                      <ReactApexChart
                        options={{
                          labels: uniqueData.map((item) =>
                            item?.ITEMDESCRIPTION?.slice(0, 15)
                          ),
                          title: {
                            text: "Pie Chart of COST PER MT INCL VAT",
                          },
                          dataLabels: {
                            enabled: true,
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (val, opts) {
                                let index = opts.dataPointIndex;
                                let name = "COST PER MT INCL VAT	";
                                return name + ": " + val;
                              },
                            },
                          },
                          width: "100%", // Set width to 100% of container
                          height: "100%", // Set height to 100% of container
                        }}
                        series={uniqueData.map(
                          (item) =>
                            item?.OT_COSTING_SHEET_CHARGE_PER_MT_INCLUSIVE ?? 0
                        )}
                        type="pie"
                      />
                    </aside>
                    <aside className="for__single__pai">
                      {" "}
                      {/* Adjust the width of each pie chart */}{" "}
                      <ReactApexChart
                        options={{
                          labels: uniqueData.map((item) =>
                            item?.ITEMDESCRIPTION?.slice(0, 15)
                          ),
                          title: {
                            text: "Pie Chart for COST PER KG INCL VAT	",
                          },
                          dataLabels: {
                            enabled: true,
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (val, opts) {
                                let index = opts.dataPointIndex;
                                let name = "COST PER KG INCL VAT	";
                                return name + ": " + val;
                              },
                            },
                          },
                          width: "100%", // Set width to 100% of container
                          height: "100%", // Set height to 100% of container
                        }}
                        series={uniqueData.map(
                          (item) =>
                            item?.OT_COSTING_SHEET_CHARGE_PER_KILO_INCLUSIVE ??
                            0
                        )}
                        type="pie"
                      />
                    </aside>
                  </div>
                )}

                {chartType === "scatter" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "scatter-chart",
                        zoom: {
                          enabled: false,
                        },
                      },
                      xaxis: {
                        // type: "datetime",
                        title: {
                          text: "ITEM DESCRIPTION",
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "COST PER KG INCL VAT	",
                          },
                        },
                        {
                          opposite: true,
                          title: {
                            text: "COST PER MT INCL VAT",
                          },
                        },
                      ],
                      title: {
                        text: "Scatter Chart",
                      },
                    }}
                    series={[
                      {
                        name: "COST PER KG INCL VAT	",
                        data: uniqueData.map((item) => ({
                          x: item?.ITEMDESCRIPTION,
                          y: item?.OT_COSTING_SHEET_CHARGE_PER_MT_INCLUSIVE,
                        })),
                      },
                      {
                        name: "COST PER MT INCL VAT",
                        data: uniqueData.map((item) => ({
                          x: item?.ITEMDESCRIPTION,
                          y: item?.OT_COSTING_SHEET_CHARGE_PER_KILO_INCLUSIVE,
                        })),
                      },
                    ]}
                    type="scatter"
                    height={300}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO RESULTS FOUND
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ImportCostingReport;
