import agri_1 from "./images/agri_1.jpg";
import agri_2 from "./images/agri_2.jpg";
import agri_3 from "./images/agri_3.jpg";
import agri_4 from "./images/agri_4.jpg";
import agri_5 from "./images/agri_5.jpg";

export const countries = [
  {
    image: agri_1,
    title: "Brazil",
  },
  {
    image: agri_2,
    title: "China",
  },
  {
    image: agri_3,
    title: "France",
  },
  {
    image: agri_4,
    title: "Japan",
  },
  {
    image: agri_5,
    title: "Norway",
  },
];
