import React, { useEffect, useState, useRef, useContext } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import "./Navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logIn } from "../../Redux/Action";
import { AuthContext } from "../../context/AuthContext";

const NavbarBarcode = () => {
  const navigate = useNavigate();
  const [showreports, setshowreports] = useState(false);
  const [showMaster, setShowMaster] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const state = useSelector((state) => state.changeTheState.user);
  const dispatch = useDispatch();
  const { setAuthUser } = useContext(AuthContext);

  function logOut() {
    dispatch(logIn([]));
    navigate("/");
    setAuthUser(null);
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="centernav">
      <div className="navbar-container">
        <nav className="navbar">
          <div className="">
            <div className="fabar" onClick={toggleMenu}>
              {isMenuOpen ? <FaTimes /> : <FaBars />}
            </div>
          </div>
          <ul className={`nav-menu ${isMenuOpen ? "open" : ""}`}>
            <div className="hover-dropdown">
              <ul className="ul__list__style__scanner">
                <div>
                  <p onClick={() => setShowMaster(!showMaster)}>MASTERS</p>
                  {showMaster && (
                    <div style={{ paddingLeft: "10px" }}>
                      <li onClick={() => navigate("/ItemMaster")}>
                        Item Master
                      </li>
                      <li onClick={() => navigate("/RouteMaster")}>
                        Route Master
                      </li>
                      <li onClick={() => navigate("/ChargesMaster")}>
                        Charges Master
                      </li>
                      <li onClick={() => navigate("/SupplierMaster")}>
                        Supplier Master
                      </li>
                      <li onClick={() => navigate("/MillMaster")}>
                        Mill Master
                      </li>
                    </div>
                  )}
                  <p onClick={() => setShowTransaction(!showTransaction)}>
                    TRANSACTIONS
                  </p>
                  {showTransaction && (
                    <div style={{ paddingLeft: "10px" }}>
                      <li onClick={() => navigate("/ImportCosting")}>
                        Import Costing
                      </li>
                      <li onClick={() => navigate("/ProductionModule")}>
                        Production Module
                      </li>
                      <li onClick={() => navigate("/Transport")}>
                        Transport Costs After Production
                      </li>
                      <li onClick={() => navigate("/CostAnalysis")}>
                        Cost Analysis
                      </li>
                    </div>
                  )}
                  <p onClick={() => setshowreports(!showreports)}>REPORTS</p>
                  {showreports && (
                    <div style={{ paddingLeft: "10px" }}>
                      <li onClick={() => navigate("/ItemMasterReport")}>
                        Item Master
                      </li>
                      <li onClick={() => navigate("/ImportCostingReport")}>
                        Import Costing
                      </li>
                      <li
                        onClick={() =>
                          navigate("/ProductionModuleRawMaterialReport")
                        }
                      >
                        Production Module Raw Material
                      </li>
                      <li
                        onClick={() =>
                          navigate("/ProductionModuleDerivedItemsReport")
                        }
                      >
                        Production Module Derived Items
                      </li>
                      <li onClick={() => navigate("/CostingTransportReport")}>
                        Transport Costs After Production
                      </li>
                      <li onClick={() => navigate("/PriceListReport")}>
                        Price List
                      </li>
                      <li onClick={() => navigate("/ReportCostAnalysis")}>
                        Report Cost Analysis{" "}
                      </li>
                    </div>
                  )}
                </div>
              </ul>
            </div>
          </ul>
        </nav>
        <div className="logedIn__as__btn">
          <h2>MarsTrack Costing</h2>
          <h3>
            Logged in as :{" "}
            {state?.emailId?.split("@")[0].toUpperCase() || "Guest"}
          </h3>
        </div>

        <aside>
          <button onClick={logOut} className="button__logout__navbar">
            LOGOUT
          </button>
        </aside>
        <div className="logout__fullscren">
          <button onClick={logOut} className="log__out__icon">
            <FiLogOut />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavbarBarcode;
