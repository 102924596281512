import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ProtectedRoute from "./context/ProtectedRoute.jsx";
import ListofProducts from "./Functionality/ListofProducts";
import LoginPage from "./Functionality/LoginPage";
import Forgotpwd from "./Functionality/Forgotpwd";
import Footer from "./Functionality/Footer";
import { useSelector } from "react-redux";
import HomePage from "./Functionality/HomePage";
import ProductionModule from "./Functionality/ProductionModule";
import Transport from "./Functionality/Transport";
import ImportCosting from "./Functionality/ImportCosting";
import ItemMaster from "./Functionality/ItemMaster";
import ItemMasterReport from "./Functionality/Reports/ItemMasterReport";
import ImportCostingReport from "./Functionality/Reports/ImportCostingReport";
import ProductionModuleRawMaterialReport from "./Functionality/Reports/ProductionModuleRawMaterialReport";
import ProductionModuleDerivedItemsReport from "./Functionality/Reports/ProductionModuleDerivedItemsReport";
import CostingTransportReport from "./Functionality/Reports/CostingTransportReport";
import RouteMaster from "./Functionality/RouteMaster";
import ChargesMaster from "./Functionality/ChargesMaster";
import CostAnalysis from "./Functionality/CostAnalysis";
import SupplierMaster from "./Functionality/SupplierMaster";
import PriceListReport from "./Functionality/Reports/PriceListReport";
import MillMaster from "./Functionality/MillMaster";
import ReportCostAnalysis from "./Functionality/Reports/ReportCostAnalysis.js";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/Forgotpwd" element={<Forgotpwd />} />
        <Route
          path="/HomePage"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ProductionModule"
          element={
            <ProtectedRoute>
              <ProductionModule />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Transport"
          element={
            <ProtectedRoute>
              <Transport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ImportCosting"
          element={
            <ProtectedRoute>
              <ImportCosting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ItemMaster"
          element={
            <ProtectedRoute>
              <ItemMaster />
            </ProtectedRoute>
          }
        />
        {/* Reports */}
        <Route
          path="/ItemMasterReport"
          element={
            <ProtectedRoute>
              <ItemMasterReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ImportCostingReport"
          element={
            <ProtectedRoute>
              <ImportCostingReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ProductionModuleRawMaterialReport"
          element={
            <ProtectedRoute>
              <ProductionModuleRawMaterialReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ProductionModuleDerivedItemsReport"
          element={
            <ProtectedRoute>
              <ProductionModuleDerivedItemsReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CostingTransportReport"
          element={
            <ProtectedRoute>
              <CostingTransportReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/RouteMaster"
          element={
            <ProtectedRoute>
              <RouteMaster />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ChargesMaster"
          element={
            <ProtectedRoute>
              <ChargesMaster />
            </ProtectedRoute>
          }
        />
        <Route
          path="/MillMaster"
          element={
            <ProtectedRoute>
              <MillMaster />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CostAnalysis"
          element={
            <ProtectedRoute>
              <CostAnalysis />
            </ProtectedRoute>
          }
        />
        <Route
          path="/SupplierMaster"
          element={
            <ProtectedRoute>
              <SupplierMaster />
            </ProtectedRoute>
          }
        />
        <Route
          path="/PriceListReport"
          element={
            <ProtectedRoute>
              <PriceListReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ReportCostAnalysis"
          element={
            <ProtectedRoute>
              <ReportCostAnalysis />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Footer />
    </Router>
  );
};
export default App;
