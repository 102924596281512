import React, { useEffect, useState } from "react";
import "../CSS/SupplierMaster.css";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AllNav from "../Components/Navbar/AllNav";
import LoadingScreen from "./LoadingScreen";

const SupplierMaster = () => {
  const state = useSelector((state) => state.changeTheState.user);
  const username = state.emailId;
  const [date, setDate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [supplierCode, setSupplierCode] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [creditLimit, setCreditLimit] = useState("");
  const [creditDays, setCreditDays] = useState("");
  const [adress, setAdress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [tableData, setTableData] = useState([]);
  const [fetchrow, setfetchrow] = useState(false);
  const [isloading, setisloading] = useState(true);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.log(error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
    getNewSupplierCode();
    getSupplierList();
  }, []);

  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      axios
        .get(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
        )
        .then((response) => {
          setCountry(response?.data?.address?.country);
          setCity(response?.data?.address?.suburb);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }, [latitude, longitude]);

  const getNewSupplierCode = () => {
    axios
      .get(`${BASEURL}/getNewSupplierCode`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setSupplierCode(response.data[0].NEWVENDORCODE);
      })
      .catch((error) => {
        console.error("Error fetching New Supplier Code Details:", error);
      });
  };

  const getSupplierList = () => {
    axios
      .get(`${BASEURL}/getSupplierList`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setisloading(false);
        setTableData(response.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Item details:", error);
      });
  };

  const handleRowClick = (value, index) => {
    setfetchrow(true);
    setSupplierCode(value.VENDORCODE);
    setSupplierName(value.VENDNAME);
    setMobileNo(value.TELEPHONE);
    setEmailId(value.EMAILADDRESS);
    setAdress(value.ADDRESS1);
    setCity(value.CITY);
    setCountry(value.COUNTRY);
    setCreditLimit(value.CREDITLIMIT);
    setCreditDays(value.CREDITDAYS);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Saving
  async function SaveSupplyMaster() {
    try {
      const response = await axios.post(
        `${BASEURL}/InsertSupplierMaster`,
        {
          VENDCODE: Number(supplierCode),
          VENDNAME: supplierName,
          VENDADDRESS: adress,
          VENDPHONE: mobileNo,
          VENDEMAIL: emailId,
          VENDCREDITLIMIT: Number(creditLimit),
          VENDCREDITDAYS: Number(creditDays),
          VENDCOUNTRY: country,
          VENDCITY: city,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        console.log(`Supplier Master saved successfully`);
      } else {
        console.error(`Error while saving Supplier Master`);
      }
    } catch (error) {
      console.error(`Error while saving Supplier Master`, error);
    }
  }

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AllNav />

      <h1 style={{ textAlign: "center" }}>Supplier Master</h1>
      <div className="header_import">
        <div className="flex">
          <button
            className="Save_import"
            onClick={() => {
              if (supplierCode || supplierName) {
                if (window.confirm("Are you sure you don't want to Save")) {
                  window.location.reload();
                }
              } else {
                window.location.reload();
              }
            }}
          >
            New
          </button>

          <button
            className="Save_import"
            onClick={async () => {
              if (!supplierCode) {
                alert("Kindly Supply Supplier Code");
              } else if (!supplierName) {
                alert("Kindly Supply Supplier Name");
              } else if (!mobileNo) {
                alert("Kindly Supply Mobile Number");
              } else if (!emailId) {
                alert("Kindly Supply Email Id");
              } else if (!creditLimit) {
                alert("Kindly Supply Credit Limit");
              } else if (!creditDays) {
                alert("Kindly Supply Credit Days");
              } else if (!adress) {
                alert("Kindly Supply Address");
              } else if (!city) {
                alert("Kindly Supply City");
              } else if (!country) {
                alert("Kindly Supply Country");
              } else {
                await SaveSupplyMaster();
                alert("Saved Succesfully");
                window.location.reload();
              }
            }}
          >
            Save
          </button>

          {/* <button
            className="Save_import"
            onClick={async () => {
              if (!supplierCode) {
                alert("Kindly Supply Supplier Code");
              } else if (!supplierName) {
                alert("Kindly Supply Supplier Name");
              } else if (!mobileNo) {
                alert("Kindly Supply Mobile Number");
              } else if (!emailId) {
                alert("Kindly Supply Email Id");
              } else if (!creditLimit) {
                alert("Kindly Supply Credit Limit");
              } else if (!creditDays) {
                alert("Kindly Supply Credit Days");
              } else if (!adress) {
                alert("Kindly Supply Address");
              } else if (!city) {
                alert("Kindly Supply City");
              } else if (!country) {
                alert("Kindly Supply Country");
              } else {
                await SaveSupplyMaster();
                alert("Updated Succesfully");
                window.location.reload();
              }
            }}
          >
            Update
          </button> */}
        </div>
      </div>
      <section className="section__Item">
        <div className="section__div">
          <div className="item_second_line_supplier">
            <div className="supplier_code">
              <p>Supplier Code</p>
              <input
                type="text"
                className="select_code"
                value={supplierCode}
                readOnly
                disabled={fetchrow == true}
              />
            </div>
            <div className="supplier_name">
              <p>Supplier Name</p>
              <input
                type="text"
                className="select_supplier"
                value={supplierName}
                onChange={(e) => {
                  setSupplierName(e.target.value);
                }}
                // disabled={fetchrow == true}
              />
            </div>
            <div className="classification">
              <p>Credit Limit</p>
              <input
                type="number"
                className="select_supplier_small"
                value={creditLimit}
                onChange={(e) => {
                  setCreditLimit(e.target.value);
                }}
              />
            </div>
            <div className="select_product_type">
              <p>Credit Days</p>
              <input
                type="number"
                className="select_supplier_small"
                value={creditDays}
                onChange={(e) => {
                  setCreditDays(e.target.value);
                }}
              />{" "}
            </div>
          </div>
          <div className="item_third_line">
            <div className="mobile_no">
              <p>Mobile No</p>
              <input
                type="number"
                className="select_supplier_medium"
                value={mobileNo}
                onChange={(e) => {
                  setMobileNo(e.target.value);
                }}
              />
            </div>
            <div className="sub-category">
              <p>Email</p>
              <input
                type="text"
                className="select_name"
                value={emailId}
                onChange={(e) => {
                  setEmailId(e.target.value);
                }}
              />
            </div>

            <div className="supplier_country">
              <p>Country</p>
              <input
                type="text"
                className="select_supplier_small"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
            </div>

            <div className="supplier_city">
              <p>City</p>
              <input
                type="text"
                className="select_supplier_small"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="item_fourth_line">
            <div className="description">
              <p>Address</p>
              <textarea
                name=""
                id=""
                className="text__area__item"
                value={adress}
                onChange={(e) => {
                  setAdress(e.target.value);
                }}
              ></textarea>
            </div>
          </div>

          <div className="item_fifth_line"></div>
        </div>

        <div
          className="visible "
          style={{
            // overflow: "auto",
            height: "auto",
            marginTop: "10px",
            padding: "4px",
            zIndex: "0",
          }}
        >
          <table className="table_1">
            <thead>
              <tr className="table-header">
                <td>SUPPLIER CODE</td>
                <td>SUPPLIER NAME</td>
                <td>CREDIT LIMIT</td>
                <td>CREDIT DAYS</td>
                <td>MOBILE NUMBER</td>
                <td>EMAIL</td>
                <td>CITY</td>
                <td>COUNTRY</td>
              </tr>
            </thead>
            <tbody>
              {tableData.map((value, index) => (
                <tr
                  onClick={() => {
                    handleRowClick(value, index);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td style={{ textAlign: "right", paddingRight: "30px" }}>
                    {value.VENDORCODE ? value.VENDORCODE : "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.VENDNAME ? value.VENDNAME : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "25px" }}>
                    {value.CREDITLIMIT
                      ? value.CREDITLIMIT.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "25px" }}>
                    {value.CREDITDAYS || "N/A"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "25px" }}>
                    {value.TELEPHONE || "N/A"}
                  </td>
                  <td style={{ paddingLeft: "20px" }}>
                    {value.EMAILADDRESS || "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.CITY || "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.COUNTRY || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default SupplierMaster;
